export default 
  [
    { code2: "af", name: "Afrikaans" },
    { code2: "sq", name: "Albanian" },
    { code2: "ar", name: "Arabic" },
    { code2: "hy", name: "Armenian" },
    { code2: "az", name: "Azerbaijani" },
    { code2: "eu", name: "Basque" },
    { code2: "be", name: "Belarusian" },
    { code2: "bg", name: "Bulgarian" },
    { code2: "ca", name: "Catalan" },
    { code2: "zh-CN", name: "Chinese (Simplified)" },
    { code2: "zh-TW", name: "Chinese (Traditional)" },
    { code2: "hr", name: "Croatian" },
    { code2: "cs", name: "Czech" },
    { code2: "da", name: "Danish" },
    { code2: "nl", name: "Dutch" },
    { code2: "en", name: "English" },
    { code2: "et", name: "Estonian" },
    { code2: "tl", name: "Filipino" },
    { code2: "fi", name: "Finnish" },
    { code2: "fr", name: "French" },
    { code2: "gl", name: "Galician" },
    { code2: "ka", name: "Georgian" },
    { code2: "de", name: "German" },
    { code2: "el", name: "Greek" },
    { code2: "ht", name: "Haitian Creole" },
    { code2: "iw", name: "Hebrew" },
    { code2: "hi", name: "Hindi" },
    { code2: "hu", name: "Hungarian" },
    { code2: "is", name: "Icelandic" },
    { code2: "id", name: "Indonesian" },
    { code2: "ga", name: "Irish" },
    { code2: "it", name: "Italian" },
    { code2: "ja", name: "Japanese" },
    { code2: "ko", name: "Korean" },
    { code2: "lv", name: "Latvian" },
    { code2: "lt", name: "Lithuanian" },
    { code2: "mk", name: "Macedonian" },
    { code2: "ms", name: "Malay" },
    { code2: "mt", name: "Maltese" },
    { code2: "no", name: "Norwegian" },
    { code2: "fa", name: "Persian" },
    { code2: "pl", name: "Polish" },
    { code2: "pt", name: "Portuguese" },
    { code2: "ro", name: "Romanian" },
    { code2: "ru", name: "Russian" },
    { code2: "sr", name: "Serbian" },
    { code2: "sk", name: "Slovak" },
    { code2: "sl", name: "Slovenian" },
    { code2: "es", name: "Spanish" },
    { code2: "sw", name: "Swahili" },
    { code2: "sv", name: "Swedish" },
    { code2: "th", name: "Thai" },
    { code2: "tr", name: "Turkish" },
    { code2: "uk", name: "Ukrainian" },
    { code2: "ur", name: "Urdu" },
  ];

