export default {
  "Product category name": 'Name der Produktkategorie',
  "Category Name": "Kategoriename",
  "Set as default shipping address": "Als Standardlieferadresse festlegen",
  'Please enter the first name': 'Bitte geben Sie den Vornamen ein',
  'Please enter the last name':"Bitte geben Sie den Nachnamen ein",
  'Please enter the address': 'Bitte geben Sie die Adresse ein',
  'Please enter the country': 'Bitte geben Sie das Land ein',
  'Please enter the province': 'Bitte geben Sie die Provinz ein',
  'Please enter the city': 'Bitte geben Sie die Stadt ein',
  'Please enter the phone': 'Bitte geben Sie das Telefon ein',
  'Please enter the postcode': 'Bitte geben Sie die Postleitzahl ein',
  "Reset": "Zurücksetzen",
  '警告提示': 'Warnhinweise',
  'Action': 'Aktion',
  'Change All': 'Alle ändern',
  'Multiply Price': 'Preis multiplizieren',
  'Add Price': 'Preis hinzufügen',
  '请联系供应商，完善站长外部编号': 'Bitte kontaktieren Sie den Anbieter, um die externe Nummer des Webmasters zu ergänzen',
  '该分类下目前还没有商品': 'Derzeit sind keine Produkte in dieser Kategorie vorhanden',
  '最大建议尺寸 200px * 200px, 图片可自适应': 'Maximal empfohlene Größe 200px * 200px, Bilder können angepasst werden',
  '添加图片': 'Bild hinzufügen',
  '更改': 'Ändern Sie',
  '删除': 'Löschen',
  '返回': 'Zurück',
  "Confirm": "Bestätigen",
  'Variants': 'Varianten',
  "Subscribe to our newsletter": "Abonniere unseren Newsletter",
  "Promotions, new products and sales. Directly to your inbox.": "Aktionen, neue Produkte und Verkäufe. Direkt in Ihren Posteingang.",
  "Your email": "Deine E-Mail",
  "Subscribe": "Abonnieren",
  "Company Information": "Firmeninformation",
  "OUR SOCIAL MEDIA": "UNSERE SOZIALEN MEDIEN",
  "PRIVACY STATEMENT": "DATENSCHUTZERKLÄRUNG",
  "Terms and Conditions": "Geschäftsbedingungen",
  "Sure": "Sicher",
  "My  Profile": "Mein Profil",
  "Login/Register": "Anmeldung Registrieren",
  "Warning Tips": "Warnung Tipps",
  "Please contact the supplier to improve the external number of the webmaster": "Bitte wenden Sie sich an den Anbieter, um die externe Nummer des Webmasters zu verbessern",
  "Black": "Schwarz",
  "There are currently no products under this category": "Derzeit gibt es keine Produkte in dieser Kategorie",
  "Logo": "Logo",
  "Maximum recommended size 200px * 200px, image can be adapted": "Maximal empfohlene Größe 200px * 200px, Bild kann angepasst werden",
  "Add image": "Bild hinzufügen",
  "Change": "Ändern",
  "Delete": "Löschen",
  "Change image": "Bild ändern",
  "Shopify tags": "Shopify-Tags",
  "Tag": "Werktage",
  "New Tag": "Neues Etikett",
  "Shopify type": "Shopify-Typ",
  "Loading": "Wird geladen",
  "Cancel": "Absagen",
  "Save": "Speichern",
  "Close": "Nah dran",
  "Select": "Auswählen",
  "Selected products": "Ausgewählte Produkte",
  " Selected stores": "Ausgewählte Geschäfte",
  "Please select the Shopify stores": "Bitte wählen Sie die Shopify-Shops aus",
  "Shopify": "Shopify",
  "Please select": "bitte auswählen",
  "Woocommerce": "WooCommerce",
  "Lazada": "Lasada",
  "Shopee": "Einkaufen",
  "Wayfair": "Wayfair",
  "Push to Store": "Zum Speichern drücken",
  "Add Cost Range": "Kostenbereich hinzufügen",
  "Rest of the price ranges": "Rest der Preisklassen",
  "Price": "Preis",
  "Plus": "Plus",
  "New Type": "Neuer Typ",
  "Multiply": "Multiplizieren",
  "Fixed Price": "Festpreis",
  "You can set a specific cent value for your price or comparison": "Sie können einen bestimmten Cent-Wert für Ihren Preis oder Vergleich festlegen",
  "price.": "Preis.",
  "Cost Price": "Selbstkostenpreis",
  "Assign compare at Cent": "Vergleich bei Cent zuweisen",
  "According to store currency": "Je nach Ladenwährung",
  "Apply for products in Import List": "Bewerben Sie sich für Produkte in der Importliste",
  "Add New Tags": "Neue Tags hinzufügen",
  "Tag name": "Name des Tags",
  "My Products": "Meine Produkte",
  "Are you sure to delete it": "Möchten Sie es wirklich löschen??",
  "Please enter the tag": "Bitte geben Sie das Tag ein",
  "Please enter the New tag": "Bitte geben Sie das neue Tag ein",
  "Import List": "Liste importieren",
  "Are you sure you want to push?": "Sind Sie sicher, dass Sie pushen möchten?",
  "Do you want to delete tish task? Tips": "Möchten Sie die Tischaufgabe löschen? Tipps",
  "Delete Completed": "Löschen abgeschlossen",
  "success": "Erfolg",
  "Your price":"Ihr Preis",
  "Fail reason": "Grund des Scheiterns",
  "Open in Web": "Im Web öffnen",
  "SortBy":"Sortieren nach",
  "You can find the recent quotations and check offers": "Sie können die letzten Notierungen finden und Angebote prüfen",
  "error": "Error",
  "The update  has started, it will take a while to finish.": "Das Update hat begonnen, es wird eine Weile dauern, bis es fertig ist.",
  "save successfully": "Erfolgreich gespeichert",
  "Do you want to delete the  product(s)?": "Möchten Sie die Produkte löschen?",
  "Delete success": "Erfolg löschen",
  "Do you want to Export the  product(s)?": "Möchten Sie das/die Produkt(e) exportieren?",
  "Export success": "Erfolg exportieren",
  "Please select the products": "Bitte wählen Sie die Produkte aus",
  "URL required": "URL erforderlich",
  "Import Product":"Produkt importieren",
  "All Stores": "Alle Läden",
  "Images": "Bilder",
  "Sold Out": "Ausverkauft",
  "Upload Progress:": "Upload-Fortschritt:",
  "Import success": "Erfolg importieren",
  "Please select the tags": "Bitte wählen Sie die Tags aus",
  "Tags added successfully": "Tags erfolgreich hinzugefügt",
  "You can import and manage your AliExpress / Amazon products here before publishing them to your Shopify store. You can publish a product to different stores and add multiple tags to each products.": "Sie können Ihre AliExpress-/Amazon-Produkte hier importieren und verwalten, bevor Sie sie in Ihrem Shopify-Shop veröffentlichen. Sie können ein Produkt in verschiedenen Shops veröffentlichen und jedem Produkt mehrere Tags hinzufügen.",
  "Import Aliexpress": "Importieren Sie hier die Produkt-URL von Aliexpress / Amazon und klicken Sie auf „Produkt importieren“.",
  "You can find the recent quotations and check offers": "Sie können aktuelle Angebote finden und Angebote prüfen",
  "Waiting for vendors respond": "Warten auf die Antwort der Anbieter",
  "Vendors responding quotations": "Anbieter antworten auf Angebote",
  "Archived quotations": "Archivierte Zitate",
  "Allocate my exist vendors":"Meine vorhandenen Lieferanten zuweisen",
  "Fulfilled": "Erfüllt",
  "Unfulfilled": "Unerfüllt",
  "Partially Fulfilled": "Teilweise erfüllt",
  "In order to keep you informed of the update of the order, we suggest you verify the email": "Um Sie über die Aktualisierung der Bestellung auf dem Laufenden zu halten, empfehlen wir Ihnen, die E-Mail zu bestätigen",
  "Refunded": "Erstattet",
  "Today": "Heute",
  "Yesterday": "Gestern",
  "Last 7 days": "Vor 7 Tagen",
  "Last 30 days": "Vor 30 Tagen",
  "Last 14 days": "Vor 14 Tagen",
  "Last 90 days": "Vor 90 Tagen",
  "Just check my orders": "Überprüfen Sie einfach meine Anweisungen",
  "Last 12 months": "Letzte 12 Monate",
  "Need new quotation for my orders": "Ich brauche ein neues Angebot für meine Aufträge",
  "Hold down the slider and drag to the right": "Halten Sie den Schieberegler gedrückt und ziehen Sie ihn nach rechts",
  "If you already have cooperative vendors, you can allocate orders to them": "Wenn Sie bereits kooperative Lieferanten haben, können Sie ihnen Aufträge zuteilen.",
  "Over 30000+ vendors waiting for offer you price": "Über 30000+ Anbieter warten auf Ihr Preisangebot",
  "orders can be process, what do you want to do now?": "Aufträge können bearbeitet werden, was wollen Sie jetzt tun?",
  "Custom": "Benutzerdefiniert",
  "Order number (ascending)": "Bestellnummer (aufsteigend)",
  "Order number (descending)": "Bestellnummer (absteigend)",
  "Date (oldest first)": "Datum (älteste zuerst)",
  "Date (newest first)": "Datum (neueste zuerst)",
  "Partially refunded": "Teilweise erstattet",
  "Allocate all to vendor": "Alles dem Lieferanten zuweisen",
  "orders selected, uncheck": "Bestellungen ausgewählt, deaktivieren",
  "orders selected, select all": "Bestellungen ausgewählt, alle auswählen",
  "orders selected": "Bestellungen ausgewählt",
  "orders": "Aufträge",
  "Orders": "Aufträge",
  "Create quotation request": "Angebotsanfrage erstellen",
  "can": "kann",
  "No order selected": "Keine Bestellung ausgewählt",
  "Has been copied to the clipboard": "Wurde in die Zwischenablage kopiert",
  "Copy to clipboard failed, please copy manually": "Kopieren in die Zwischenablage fehlgeschlagen, bitte manuell kopieren",
  "waiting for responding": "auf Antwort warten",
  "Cancelled": "Abgebrochen",
  "Click the": "Klicken Sie auf die",
  "Allocate to vendor": "Dem Lieferanten zuordnen",
  "button on the bottom of the page.": "am unteren Rand der Seite.",
  "Confirmed": "Bestätigt",
  "View offers": "Angebote ansehen",
  "Cancel offers": "Angebote stornieren",
  "Check offers": "Angebote prüfen",
  "View offer detail": "Angebotsdetails anzeigen",
  "Fulfillment": "Erfüllung",
  "Order": "Befehl",
  "My Store": "Mein Laden",
  "Date": "Datum",
  "Customer": "Kunde",
  "Total": "Gesamt",
  "Payment": "Zahlung",
  "Start date": "Datum des Beginns",
  "End date": "Enddatum",
  "Fulfillment": "Erfüllung",
  "Items": "Artikel",
  "Product images": "Produktbilder",
  "Details": "Einzelheiten",
  "Expected price (US$)": "Erwarteter Preis (US$)",
  "Remark": "Anmerkung",
  "Abnormal reason":"Abnormaler Grund",
  "Vendors respond": "Anbieter reagieren",
  "Status": "Status",
  "Vendor name": "Herstellername:",
  "Offered Orders": "Angebotene Bestellungen",
  "Attribute Value":"Attributwert",
  "Offered Products": "Angebotene Produkte",
  "Offer price": "Angebotspreis",
  "View offer detail": "Angebotsdetails anzeigen",
  "Discard": "Verwerfen",
  "Confirm this Offer": "Bestätigen Sie dieses Angebot",
  "Offered Orders": "Angebotene Bestellungen",
  "Offered Products": "Angebotene Produkte",
  "All Orders": "Alle Bestellungen",
  "All Products": "Alle Produkte",
  "Yes, I want to CANCEL this offer": "Ja, ich möchte dieses Angebot STORNIEREN",
  "Cancel the success": "Stornieren Sie den Erfolg",
  "Track number": "Titelnummer",
  "Please input the tracking number": "Bitte geben Sie die Sendungsverfolgungsnummer ein",
  "Track company": "Unternehmen verfolgen",
  "Please select logistics company": "Bitte Logistikunternehmen auswählen",
  "Your request has been sent": "Ihre Anfrage wurde verschickt",
  "If you do not check the relevant permissions, it will affect the use of related functions, please operate with caution": "Wenn Sie die entsprechenden Berechtigungen nicht überprüfen, wirkt sich dies auf die Nutzung der entsprechenden Funktionen aus, bitte gehen Sie vorsichtig vor",
  "The receiving address has no logistics method set": "Für die Empfangsadresse ist keine Logistikmethode festgelegt",
  "The order is below the minimum deliverable weight": "Die Bestellung liegt unter dem Mindestliefergewicht",
  "The order is below the minimum deliverable volume": "Die Bestellung liegt unter dem Mindestliefervolumen",
  "The order is higher than the maximum deliverable weight": "Die Bestellung ist höher als das maximal lieferbare Gewicht",
  "The order is higher than the maximum deliverable volume": "Die Bestellung ist höher als das maximal lieferbare Volumen",
  "The following product attributes of this order do not meet the attribute requirements of deliverable products": "Die folgenden Produktattribute dieser Bestellung erfüllen nicht die Attributanforderungen für lieferbare Produkte",
  "The total weight of the goods in this order does not meet the deliverable requirements": "Das Gesamtgewicht der Ware in dieser Bestellung entspricht nicht den lieferbaren Anforderungen",
  "Volume(cm)": "Volumen (cm)",
  "Total volume(cm)": "Gesamtvolumen (cm)",
  "Attribute": "Attribut",
  "Total weight": "Total weight",
  "Pay": "Bezahlen",
  "I Know": "Ich weiß",
  "订单信息": "Bestellinformationen",
  "第三方订单编号": "Bestellnummer des Drittanbieters",
  "订单编号": "Bestellnummer",
  "店铺": "Laden",
  "Waiting": "wartend",
  "创建时间": "Erstellungszeitpunkt",
  "开泰银行支付": "Bezahlen Sie mit der Kaitai Bank",
  "Waiting for the payment result to be returned.": "Warten auf die Rückgabe des Zahlungsergebnisses.",
  "You may need to wait a little while": "Möglicherweise müssen Sie eine Weile warten",
  "View order": "Bestellung ansehen",
  "Payment successful": "Bezahlung erfolgreich",
  "You have successfully completed your order.": "Sie haben Ihre Bestellung erfolgreich abgeschlossen.",
  "Sorry, we can't complete the payment.": "Wir können die Zahlung leider nicht abschließen.",
  "Pay again": "Zahlen Sie erneut",
  "Order No.": "Best.-Nr.",
  "Receiving": "Empfang",
  "days": "Tage",
  "Store price": "Preis speichern",
  "Shipping days ": "Versandtage",
  "Subtotal": "Zwischensumme",
  "Commodity Price": "Warenpreis",
  'Total Merchandise': 'Gesamtware',
  'SKU Details': 'SKU Details',
  "Freight": "Fracht",
  "Discount": "Rabatt",
  "Exchange": "Austausch",
  "Bonus": "Bonus",
  "Credits": "Kredite",
  "Combination payment":"Kombinationszahlung",
  "Please enter a value greater than 0": "Bitte geben Sie einen Wert größer als 0 ein",
  "Please enter a value less than the total amount of the order": "Bitte geben Sie einen Wert ein, der unter dem Gesamtbetrag der Bestellung liegt",
  "Please enter the balance to be used": "Bitte geben Sie den zu verwendenden Saldo ein",
  "Rules: The input amount should be less than the order amount, and only two decimal places": "Regeln: Der Eingabebetrag sollte kleiner als der Bestellbetrag sein und nur zwei Dezimalstellen betragen",
  "After the combination payment is enabled, the account balance will be deducted first, and the rest will be paid by other selected methods": "Nachdem die Kombinationszahlung aktiviert wurde, wird zunächst der Kontostand abgebucht und der Rest über andere ausgewählte Methoden beglichen",  
  "Quality": "Menge",
  "Payment method": "Zahlungsmethode",
  "The supplier has opened a credit limit for you. When your account balance is insufficient, you can deduct the credit limit": "Der Lieferant hat für Sie ein Kreditlimit eingerichtet. Wenn Ihr Kontostand nicht ausreicht, können Sie das Kreditlimit in Anspruch nehmen",
  "There is no alternative method of payment": "Es gibt keine alternative Zahlungsmethode",
  "Quantity of order ": "Bestellmenge",
  "Total amount of goods": "Gesamtmenge der Ware",
  "Tax Amount": "Steuerbetrag",
  "Discounted price of goods": "Reduzierter Warenpreis",
  "Total freight amount": "Gesamtfrachtbetrag",
  "Shop discount": "Shop-Rabatt",
  "Amount due (including freight)": "Fälliger Betrag (einschließlich Fracht)",
  "This document is free of freight": "Dieses Dokument ist frachtfrei",
  "credit&debit card": "Kredit- und Debitkarte",
  "qr payment": "QR-Zahlung",
  "请稍后重试～": "Bitte versuchen Sie es später noch einmal~",
  "Please select payment method": "Bitte Zahlungsmethode auswählen",
  "Order Management": "Bestellverwaltung",
  "Import order": "Bestellung importieren",
  "Export order": "Auftrag exportieren",
  "Filter": "Filter",
  "Clear": "Update",
  "Create a quote": "Erstellen Sie ein Angebot",
  "Quit quotation": "Zitat beenden",
  "Bulk payment": "Massenzahlung",
  "Cancel payment": "Zahlung stornieren",
  "Manually synced": "Manuell synchronisiert",
  "Specifications": "Eigenschaften",
  "GO": "Los",
  "Continue Pay": "Bezahlen Sie weiter",
  "Re quote": "Wieder zitieren",
  "Your shop order num": "Ihre Shop-Bestellnummer",
  "Unable to purchase": "Kauf nicht möglich",
  "null     Please make an offer": "null     Bitte machen Sie ein Angebot",
  "Supply unit price": "Preis der Liefereinheit",
  "about": "über",
  "Trial calculation of shipping costs": 'Versuchsweise Berechnung der Versandkosten',
  "Return able with in": "Rückgabefähig mit in",
  "Shop price：": "Ladenpreis:",
  "Please make an offer": "Bitte machen Sie uns ein Angebot",
  "Removed": "ENTFERNT",
  "Update Product": "Produkt aktualisieren",
  "Delete": "Löschen",
  "Your No.": "Ihre Nr.",
  "Invite Vendor": "Anbieter einladen",
  "Shop name": "Name des Geschäfts:",
  "Unable to purchase": "Kauf nicht möglich",
  "AilExpress shop：": "AilExpress-Shop：",
  "Invite Link:":"Link einladen:",
  "Bonus Detail": "Bonus Detail",
  "null     Please make an offer": "null Bitte machen Sie ein Angebot",
  "Products Off shelf": "Produkte aus dem Regal",
  "Please make an offer": "Bitte machen Sie uns ein Angebot",
  "warehouse": "Warenhaus",
  "address": "die Anschrift",
  "Customer": "Kunde",
  "Products": "Produkte",
  "Shipping cost()": "Versandkosten()",
  "Manually synced": "Manuell synchronisiert",
  "Payment time": "Zahlungszeit",
  "Payment voucher": "Payment voucher",
  "Payment amount": "Zahlungsbetrag",
  "Payment instructions：": "Zahlungsanweisungen：",
  "Forgot your password?":"Haben Sie Ihr Passwort vergessen?",
  "Other details":"Other details",
  "or Sign in with": "oder Anmelden mit",
  "Already have an account?": "Haben Sie bereits ein Konto?",
  "Payment account": "Zahlungskonto",
  "Delivery Address": "Lieferadresse",
  "Inventory lock remaining time": "Verbleibende Zeit der Inventarsperre",
  "Order quantity": "Bestellmenge",
  "inventory": "Auf Lager",
  "Cancel payment": "Zahlung stornieren",
  "Your shop order num": "Ihre Shop-Bestellnummer",
  "Unable to purchase": "Kauf nicht möglich",
  "null     Please make an offer": "null Bitte machen Sie ein Angebot",
  "Products Off shelf`": "Produkte aus dem Regal",
  "Please make an offer": "Bitte machen Sie uns ein Angebot",
  "Created Time": "Geschaffene Zeit",
  "Request Fullfill": "Fullfill anfordern",
  "Shipping Method": "Versandart",
  "Shipping cost": "Versandkosten",
  "Unable to be sourced":"Kann nicht beschafft werden",
  "pay total": "insgesamt bezahlen",
  "Select Payment Method": "Wählen Sie die Zahlungsmethode",
  "All transactions are secure and encrypted": "Alle Transaktionen sind sicher und verschlüsselt",
  "Pay with": "Bezahlen mit",
  "There is no alternative method of payment": "Es gibt keine alternative Zahlungsmethode",
  "Please do not close the page during payment": "Bitte schließen Sie die Seite nicht während der Zahlung",
  "Close pay": "enge Bezahlung",
  "Your order has been successfully paid": "Ihre Bestellung wurde erfolgreich bezahlt",
  "The selected orders are abnormal and payment cannot be continued": "Die ausgewählten Bestellungen sind anormal und die Zahlung kann nicht fortgesetzt werden",
  "Payment can continue after excluding the following goods": "Die Zahlung kann nach dem Ausschluss der folgenden Waren fortgesetzt werden",
  "After removing the off shelf goods, the order will be returned to the": "Nach Entnahme der Auslagerware wird die Bestellung an die zurückgeschickt",
  "price to be quoted": "Preis zu nennen",
  "Confirm": "bestätigen",
  "选取文件": "Datei aussuchen",
  "点击下载": "Klicken Sie hier zum Herunterladen",
  "订单导入模板": "Importvorlage bestellen",
  "请按规范填写表格，仅支持xlsx格式文件": "Bitte füllen Sie das Formular gemäß den Vorgaben aus, es werden nur Dateien im xlsx-Format unterstützt",
  "上传的文件不能超过2M，只处理前99条数据": "Die hochgeladene Datei darf 2 MB nicht überschreiten und es werden nur die ersten 99 Daten verarbeitet",
  "submit": "einreichen",
  "Select a vendor": "Wählen Sie einen Anbieter",
  "Please select a shipping address": "Bitte wählen Sie eine Lieferadresse",
  "Please select a shipping method":"Bitte wählen Sie eine Versandart",
  "Quotation to be submitted": "Angebot einzureichen",
  "Vendor quoted order": "Vom Lieferanten angebotene Bestellung",
  "Payment Not Received": "Zahlung nicht erhalten",
  "Pending payment": "Ausstehende Zahlung",
  "Fulfilled": "Erfüllt",
  "Waiting": 'wartend',
  "Processing": 'In Bearbeitung',
  "Completed": "Abgeschlossen",
  "Logistics synchronization failed": "Logistiksynchronisierung fehlgeschlagen",
  "Unable to purchase": "Kauf nicht möglich",
  "Products Off shelf": "Produkte aus dem Regal",
  "Payment to be completed": "Zahlung abgeschlossen werden",
  "Waiting for fulfill": "Warten auf Erfüllung",
  "Quit quotation": "Zitat beenden",
  "Pending Payment": "Ausstehende Zahlung",
  "Pending": "Ausstehend",
  "danger": "Achtung",
  "warning": "Warnung",
  "Vendor Verifying Payment": "Verkäufer verifiziert die Zahlung",
  "Payment result to be returned": "Zurückzugebendes Zahlungsergebnis",
  "确定删除该商品？": "Möchten Sie dieses Element wirklich löschen?",
  "确定": "Sicher",
  "取消": "Absagen",
  "Delete Successful!": "Löschen erfolgreich!",
  "已取消": "Abgebrochen",
  "Please select an order": "Bitte wählen Sie eine Bestellung aus",
  "Binding succeeded!": "Bindung erfolgreich!",
  "There is no warehouse, please go to add！": "Es gibt kein Lager, bitte gehen Sie zum Hinzufügen!",
  "Please choose file": "Bitte Datei auswählen",
  "file cannot over 2MB": "Datei darf 2 MB nicht überschreiten",
  "Create Success": "Erfolg schaffen",
  "No goods need to be eliminated": "Es müssen keine Waren ausgesondert werden",
  "Please select an order firs": "Bitte wählen Sie zuerst eine Bestellung aus",
  "Successfully": "Erfolgreich",
  "No logistics": "Keine Logistik",
  "Are you sure to abandon payment for the selected order? After abandoning, the order will disappear from the list, which cannot be restored, please operate with caution": "Möchten Sie die Zahlung für die ausgewählte Bestellung wirklich abbrechen? Nach dem Abbrechen verschwindet die Bestellung aus der Liste, die nicht wiederhergestellt werden kann. Bitte seien Sie vorsichtig",
  "Are you sure to initiate a quotation for the selected order?": "Möchten Sie wirklich ein Angebot für die ausgewählte Bestellung erstellen?",
  "Are you sure to abandon the quotation for the selected order? After giving up, it cannot be restored, please operate with caution": "Möchten Sie das Angebot für die ausgewählte Bestellung wirklich aufgeben? Nach dem Aufgeben kann es nicht wiederhergestellt werden, gehen Sie bitte mit Vorsicht vor",
  "Payment has not arrived": "Zahlung ist nicht angekommen",
  "Payment to be completed": "Zahlung abgeschlossen werden",
  "Waiting for fulfillment": "Warten auf Erfüllung",
  "Logistics synchronization failed": "Logistiksynchronisierung fehlgeschlagen",
  "Unable to purchase": "Kauf nicht möglich",
  "Products Off shelf": "Produkte aus dem Regal",
  "Order ID": "Auftragsnummer",
  "My stores": "meine Geschäfte",
  "Fulfillment Status": "Erfüllungsstatus",
  "Export to": "Exportieren nach",
  "Selected": "Ausgewählt",
  "Save Rule": "Regel speichern",
  "Some orders had been fulfilled or refunded": "Einige Bestellungen wurden erfüllt oder zurückerstattet",
  "Export orders": "Bestellungen exportieren",
  "Choose my all Shopify products": "Wählen Sie alle meine Shopify-Produkte",
  "<strong>Your Orders are exporting</strong><br/>Your export will be delivered by email to: . <br/>Depending on how many Orders you’re exporting, this could take some time.": "<strong>Ihre Bestellungen werden exportiert</strong><br/>Ihr Export wird per E-Mail an folgende Adresse gesendet: . <br/>Je nachdem, wie viele Bestellungen Sie exportieren, kann dies einige Zeit dauern.",
  "Notice": "Notiz",
  "Profit": "Gewinn",
  "You have": "Sie haben",
  "Order Includes": "Die Bestellung umfasst",
  "purchase order(s) need your reconfirm,": "Bestellung(en) benötigen Ihre Rückbestätigung,",
  "click here": "Klicke hier",
  "need your reconfirm,": "benötigen Ihre Rückbestätigung,",
  "to view details.": "Details anzuzeigen.",
  "Clear selection": "Auswahl löschen",
  "Total Quantity selected": "Ausgewählte Gesamtmenge",
  "Total Quantity": "Gesamtmenge",
  "Current Unit Cost": "Aktuelle Stückkosten",
  "Vendor shipped": "Anbieter verschickt",
  "unfulfilled / partially fulfilled)": "nicht erfüllt / teilweise erfüllt)",
  "But": "Aber",
  "You may download these tracking information and fulfilled these orders in your Shopify store manualy.": "Sie können diese Tracking-Informationen herunterladen und diese Bestellungen in Ihrem Shopify-Shop manuell ausführen.",
  "Download ALL tracking information": "Laden Sie ALLE Tracking-Informationen herunter",
  "sync failed": "Synchronisierung fehlgeschlagen",
  "Reconfirm": "Bestätigen Sie erneut",
  "View Details": "Details anzeigen",
  "Change another vendor": "Anderen Anbieter wechseln",
  "Export": "Export",
  "Cancel this order": "Diese Bestellung stornieren",
  "NOT Joined": "NICHT beigetreten",
  "Amount Due": "Offener Betrag",
  "Not set up": "Nicht eingerichtet",
  "Share by link": "Per Link teilen",
  "Share link": "Einen Link teilen",
  "Copy Link": "Link kopieren",
  "Share by APP": "Per APP teilen",
  "Title": "Titel",
  "Desc": "Beschr",
  "Share": "Teilen",
  "Scan share to WeChat": "Scannen Sie die Freigabe an WeChat",
  "Includes": "Beinhaltet",
  "Shopify orders": "Shopify-Bestellungen",
  "products": "Produkte",
  "Do you want to cancel this order?": "Möchten Sie diese Bestellung stornieren?",
  "Don't worry, after cancelling this purchase order, you can still find your": "Keine Sorge, nachdem Sie diese Bestellung storniert haben, können Sie Ihre immer noch finden",
  "Shopify orders in the Waiting for sourcing menu and re-place the purchase order": "Shopify-Bestellungen im Menü „Warten auf Beschaffung“ und erneuern Sie die Bestellung",
  "Discard": "Verwerfen",
  "Change purchase order to another vendor": "Ändern Sie die Bestellung zu einem anderen Lieferanten",
  "Current vendor:": "Aktueller Anbieter:",
  "Change to new vendor": "Wechseln Sie zu einem neuen Anbieter",
  "All my vendors": "Alle meine Anbieter",
  "Also allocate the products in this purchase order to the new supplier": "Ordnen Sie auch die Produkte dieser Bestellung dem neuen Lieferanten zu",
  "Order created": "Bestellung erstellt",
  "Order paid": "Bestellung bezahlt",
  "Use this button to update your Order List if missing orders.The process may take a while to finish.": "Verwenden Sie diese Schaltfläche, um Ihre Auftragsliste zu aktualisieren, wenn Aufträge fehlen, der Vorgang kann eine Weile dauern.",
  "Order confirmed": "Bestellung bestätigt",
  "Copy to clipboard failed, please copy manually": "Kopieren in die Zwischenablage fehlgeschlagen, bitte manuell kopieren",
  "The update process has started, it will take a while to finish.": "Der Aktualisierungsvorgang hat begonnen, es wird eine Weile dauern, bis er abgeschlossen ist.",
  "Update Order": "Bestellung aktualisieren",
  "Not purchased": "Nicht gekauft",
  "You have": "Du hast",
  "purchase orders": "Kauforder",
  "NEED to finish payment": "MÜSSEN die Zahlung abschließen",
  "Waiting for allocated": "Warten auf Zuteilung",
  "Waiting for sourcing ": "Warten auf Beschaffung",
  "Orders processing": "Auftragsabwicklung",
  "Abnormal order ": "Abnormale Reihenfolge",
  "Abnormal":"Ungewöhnlich",
  "Quote for Orders": "Angebot für Bestellungen",
  "Quote for Product": "Angebot für Produkt",
  "Recent Quotations": "Aktuelle Zitate",
  "Quotation": "Angebot",
  "orders matching your search": "Bestellungen, die Ihrer Suche entsprechen",
  "Export order": "Auftrag exportieren",
  "How to process your orders?": "Wie werden Ihre Aufträge bearbeitet?",
  "Email": "Email",
  "Change": "Rückgeld",
  "Please select at least one order and quote for your order": "Bitte wählen Sie mindestens einen Auftrag und ein Angebot für Ihren Auftrag",
  "Get captcha": "Captcha erhalten",
  "try again in": "versuchen Sie es erneut in",
  "seconds": "Sekunden",
  "Choose orders": "Aufträge auswählen",
  "Please fill in the correct Email address": "Bitte geben Sie die korrekte Email-Adresse ein",
  "User Email modified successfully": "Benutzer-E-Mail erfolgreich geändert",
  "Your Orders are exporting</strong><br/>Your export will be delivered by email to": "Ihre Bestellungen werden exportiert</strong><br/>Ihr Export wird per E-Mail zugestellt an",
  "Depending on how many Orders you’re exporting, this could take some time.": "Je nachdem, wie viele Aufträge Sie exportieren, kann dies einige Zeit dauern.",
  "Your Orders are exporting</strong><br/>Your export will be delivered by email to": "Ihre Bestellungen werden exportiert</strong><br/>Ihr Export wird per E-Mail an geliefert",
  "<br/>Depending on how many Orders you’re exporting, this could take some time": "<br/>Je nachdem, wie viele Bestellungen Sie exportieren, kann dies einige Zeit dauern",
  "Show cancelled orders": "Stornierte Bestellungen anzeigen",
  "unfulfilled / partially fulfilled": "nicht erfüllt / teilweise erfüllt",
  "Download NOT FULFILLED tracking information": "Laden Sie Tracking-Informationen für NICHT ERFÜLLT herunter",
  "sync failed": "Synchronisierung fehlgeschlagen",
  "Change another vendor": "Anderen Anbieter wechseln",
  "Invite vendor to process": "Lieferanten zur Bearbeitung einladen",
  "Cancel this order": "Diese Bestellung stornieren",
  "More Action": "Mehr Aktionen",
  "More": "Mehr",
  "Selected saved! Now you can Check the purchase orders": "Auswahl gespeichert Jetzt können Sie die Bestellungen prüfen",
  "Send to vendor": "an den Anbieter senden",
  "After your confirmation for the payment, we will notify the vendor by email. You can also proactively notify the vendor to speed the delivery up": "Nachdem Sie die Zahlung bestätigt haben, benachrichtigen wir den Verkäufer per E-Mail. Sie können den Verkäufer auch proaktiv benachrichtigen, um die Lieferung zu beschleunigen",
  "Have you finished the payment?": "Sind Sie mit der Zahlung fertig?",
  "Confirm the payment": "Bestätigen Sie die Zahlung",
  "Purchase Order Reconfirm":"Bestellung rückbestätigen",
  "Cancel purchase order": "Bestellung stornieren",
  "Your request is been submitted, please wait one moment.": "Ihre Anfrage wurde übermittelt, bitte warten Sie einen Moment.",
  "The reason must be entered": "Der Grund muss eingetragen werden",
  "In this tab, we will only filter your": "Auf dieser Registerkarte filtern wir nur Ihre",
  "Orders which you can allocate to your vendor to process.": "Bestellungen, die Sie Ihrem Lieferanten zur Bearbeitung zuweisen können.",
  "Date Ranges": "Datumsbereiche",
  "Unfulfilled": "Unerfüllt",
  "Partially fulfilled": "Teilweise erfüllt",
  "Fulfillment Status": "Erfüllungsstatus",
  "Allocate all to vendor": "Alle dem Lieferanten zuweisen",
  "Allocate": "Zuweisen",
  "Add Vendor": "Anbieter hinzufügen",
  "Cost per unit": "Kosten pro Einheit",
  "button of the page,you can also allocate items from the same SPU to the same vendor":  "Über die Schaltfläche auf der Seite können Sie auch Artikel aus derselben SPU demselben Lieferanten zuordnen",
  "purchased items are delivered": "Gekaufte Artikel werden geliefert",
  "Product has been allocated": "Produkt wurde zugeteilt",
  "Bonus Balance（$）": "  Bonus-Guthaben（$）",
  "Sales statistics": "Verkaufsstatistik",
  "Vendor name / contact / shop name etc.": "Anbietername / Kontakt / Ladenname etc.",
  "Here are some other products seems can been allocated to the vendor too, do you want to do it now?": "Hier sind einige andere Produkte, die anscheinend auch dem Anbieter zugewiesen werden können. Möchten Sie dies jetzt tun?",
  "Selected products": "Ausgewählte Produkte",
  "Allocate NOW": "JETZT zuweisen",
  "My sourcing products": "Meine Beschaffungsprodukte",
  "No thanks": "Nein danke",
  "Vendor information": "Informationen zum Anbieter",
  "Please select at least one order": "Bitte wählen Sie mindestens eine Bestellung aus",
  "Create quotation request?": "Angebotsanfrage erstellen?",
  "Copy to clipboard failed, please copy manually": "Kopieren in die Zwischenablage fehlgeschlagen, bitte manuell kopieren",
  "Order ID From:": "Bestell-ID Von:",
  "Vendor Contact": "Kontakt zum Anbieter",
  "You're going to place": "Sie werden Platz nehmen",
  "orders to": "Befehle zu",
  "vendors": "Anbieter",
  "responded": "antwortete",
  "Offers": "Angebote",
  "Product": "Produkt",
  "Add Tracking": "Tracking hinzufügen",
  "Total Product": "Total Product",
  "Shopify vendor": "Shopify-Anbieter",
  "You can choose the payment method you can accept, we will submit your": "Sie können die Zahlungsmethode auswählen, die Sie akzeptieren können, wir werden Ihre übermitteln",
  "orders and options to your vendors": " Bestellungen und Optionen an Ihre Lieferanten",
  "Warm tips": "Warme Tipps",
  "You have set the following products to be allocated to the vendors": "Sie haben festgelegt, dass die folgenden Produkte den Lieferanten zugeordnet werden sollen",
  "only once. Therefore, the following items will need to be": " nur einmal. Daher müssen die folgenden Elemente vorhanden sein",
  "re-allocated after the orders are generated again.": " neu zugewiesen, nachdem die Bestellungen erneut generiert wurden.",
  "By AliExpress VIP link": "Per AliExpress VIP-Link",
  "Un-allocate selected products?": "Zuweisung ausgewählter Produkte aufheben?",
  "请输入单号范围": "Bitte geben Sie einen einzelnen Nummernkreis ein",
  "起始单号必须小于结束单号": "Die Startauftragsnummer muss kleiner als die Endauftragsnummer sein",
  "Product title": "Produktname",
  "Expected price": "Erwarteter Preis",
  "Reply price": "Antwortpreis",
  "Reply link": "Antwortlink",
  "Failure reason": "Ausfallgrund",
  "Store products": "Produkte lagern",
  "Publish": "Veröffentlichen",
  "Query": "Anfrage",
  "Reset": "Zurücksetzen",
  "Price units default to": "Preiseinheiten standardmäßig auf",
  "Please upload pictures": "Bitte Bilder hochladen",
  "Please enter title": "Bitte Titel eingeben",
  "Please enter": "Bitte eintreten",
  "State": "Zustand",
  "Penging": "Peng",
  "Failed": "Abgebrochen",
  "All orders":"Alle Aufträge",
  "Platform Reply": "Plattform-Antwort",
  "All": "alle",
  "Base Information":"Informationen zur Basis",
  "Description": "Beschreibung:",
  "Edit": "Bearbeiten",
  "ADD": "HINZUFÜGEN",
  "Destination Country": "Bestimmungsland:",
  "Please enter description": "Bitte Beschreibung eingeben",
  "Please enter IOSS ID": "Bitte IOSS-ID eingeben",
  "Please select destination country": "Bitte Zielland auswählen",
  "Please select at least 1 destination country.": "Bitte wählen Sie mindestens 1 Zielland aus.",
  "Please enter an IOSS ID with 12 alphanumeric characters.": "Bitte geben Sie eine IOSS-ID mit 12 alphanumerischen Zeichen ein.",
  "After saving, your IOSS will be updated to the corresponding order. Are you sure to save?": "Nach dem Speichern wird Ihr IOSS auf die entsprechende Bestellung aktualisiert. Möchten Sie wirklich speichern?",
  "Dashboard": "Armaturenbrett",
  "Settings": "Einstellungen",
  "According to the new European VAT e-commerce rules, declaration without lOSS will result in a delay in customs clearance and the recipient will be required to pay VAT, as the parcel will be detained when contact of the recipient is incorrect.": "Gemäß den neuen europäischen Mehrwertsteuerregeln für den E-Commerce führt eine Deklaration ohne Verlust zu einer Verzögerung der Zollabfertigung und der Empfänger muss die Mehrwertsteuer zahlen, da das Paket zurückgehalten wird, wenn der Empfänger inkorrekt kontaktiert wird.",
  "IOSS Option": "IOSS-Option",
  "No IOSS": "Kein IOS",
  "The amount of the orders (shipping cost included) will be declared and recipients will be required by the customs to pay VAT when parcels arrive in the destination countries of EU": "Der Betrag der Bestellungen (einschließlich Versandkosten) wird deklariert und die Empfänger werden vom Zoll zur Zahlung der Mehrwertsteuer aufgefordert, wenn die Pakete in den Bestimmungsländern der EU ankommen",
  "Please enter correct and valid IOSS IDs, which should be linked to corresponding destination countries. IOSS ID will not be applied if the destination country is not linked to a correct IOSS ID": "Bitte geben Sie korrekte und gültige IOSS-IDs ein, die mit den entsprechenden Zielländern verknüpft werden sollten. Die IOSS-ID wird nicht angewendet, wenn das Zielland nicht mit einer korrekten IOSS-ID verknüpft ist",
  "My IOSS Settings": "Meine IOSS-Einstellungen",
  "Linked Countries": "Verbundene Länder",
  "Add IOS": "iOSS hinzufügen",
  "Unlinked Countries:": "Nicht verknüpfte Länder:",
  "Are you sure to delete the IOSS?": "Möchten Sie das IOSS wirklich löschen?",
  "More categories": "Mehr Kategorien",
  "AliExpress store information": "AliExpress Shop-Informationen",
  "Detailed seller ratings": "Detaillierte Verkäuferbewertungen",
  "Recent Products": "Kürzlich hinzugefügte Produkte",
  "Store No.": "Shop-Nr.",
  "This store has been open since": "Seitdem ist dieser Laden geöffnet",
  "Positive feedback": "Positives Feedback",
  "Business License": "Geschäftslizenz",
  "Item as Described": "Artikel wie beschrieben",
  "Above": "Über",
  "Below": "Unter",
  "Sourcing Now": "Jetzt beschaffen",
  "Average": "Durchschnitt",
  "Shipping Speed": "Versandgeschwindigkeit",
  "Detailed Seller Ratings information is unavailable when there're less than 10 rating": "Detaillierte Informationen zu Verkäuferbewertungen sind nicht verfügbar, wenn weniger als 10 Bewertungen vorliegen",
  "Add vendors success": "Anbietererfolg hinzufügen",
  "Add a new vendor": "Fügen Sie einen neuen Anbieter hinzu",
  "My Vendors": "Meine Anbieter",
  "Amount($)":"Betrag($)",
  "Product name": "Produktname",
  "Edit Vendor": "Anbieter bearbeiten",
  "By AliExpress Url": "Von AliExpress-URL",
  "Please enter your vendor's name": "Bitte geben Sie den Namen Ihres Anbieters ein",
  "Vendor's Name": "Name des Anbieters",
  "Vendor's AliExpress shop or product URL": "Der AliExpress-Shop oder die Produkt-URL des Anbieters",
  "How to get the vendor's AliExpress shop / product URL?": "Wie erhalte ich die AliExpress-Shop-/Produkt-URL des Verkäufers?",
  "Transaction Flow Line": "Transaktionsablauflinie",
  "Are you sure you want to remove entrusted vendor?": "Sind Sie sicher, dass Sie den beauftragten Lieferanten entfernen wollen?",
  "Recharge Time": "Aufladezeit",
  "Are you sure you want to delete it?": "Möchten Sie es wirklich löschen?",
  "Customer name required": "Kundenname erforderlich",
  "customer successfully edited": "Kunde erfolgreich bearbeitet",
  "customer successfully added": "Kunde erfolgreich hinzugefügt",
  "Address Management": "Adressverwaltung",
  "Add New Address": "Neue Adresse hinzufügen",
  "Default Shipping Address": "Standardlieferadresse",
  "First name": "Vorname",
  "Last name": "Nachname",
  "Phone": "Telefon",
  "Address": "die Anschrift",
  "City": "Stadt",
  "Postcode": "Postleitzahl",
  "Province": "Provinz",
  "Country": "Land",
  "Edit": "Bearbeiten",
  "Delete": "Löschen",
  "No address": "keine Adresse",
  "Do you want to delete?": "Möchten Sie löschen?",
  "delete successfully": "erfolgreich löschen",
  "save successfully": "Erfolgreich gespeichert",
  "My Allocated Product": "Mein zugewiesenes Produkt",
  "Batch Re-allocate": "Stapel neu zuweisen",
  "Batch Un-allocate": "Batch-Zuweisung aufheben",
  "All my stores": "Alle meine Geschäfte",
  "UN-allocate Success": "UN-Zuteilung Erfolg",
  "Products Market": "Produktmarkt",
  "Home": "Heim",
  "Date type": "Datumsart",
  "My Profile": "Mein Profil",
  "Logout": "abmelden",
  "LOGIN": "ANMELDUNG",
  "Stock Qty": 'Warenbestand',
  "Sign up here": "Hier anmelden",
  "Attach image file": "Bilddatei anhängen",
  "Browse": "Durchsuche",
  "Customer Support Email": "E-Mail des Kundensupports",
  "Send your request": "Senden Sie Ihre Anfrage",
  "Powered by OurMall": "Unterstützt von OurMall",
  "Privacy policy": "Datenschutz-Bestimmungen",
  "Terms of use": "Nutzungsbedingungen",
  "Refund Policy": "Rückgaberecht",
  "Contact Us": "Kontaktiere uns",
  "Getting Started": "Einstieg",
  "Main Features": "Haupteigenschaften",
  "FAQs": "Häufig gestellte Fragen",
  "Total Orders": "Bestellungen insgesamt",
  "OurMall Dropshipping - Easy Dropshipping": "OurMall Dropshipping - Einfaches Dropshipping",
  "The question must not be empty": "Die Frage darf nicht leer sein",
  "The email must not be empty": "Die E-Mail darf nicht leer sein",
  "Send Success,We will contact you soon.": "Senden Sie Erfolg, wir werden uns bald mit Ihnen in Verbindung setzen.",
  "All Items": "Alle Elemente",
  "back": "der Rücken",
  "Check All": "Alle überprüfen",
  "Digital Themrmometer Hygrometer Living Ro Digital Themrmometer Hygrometer Living Ro": "Digitales Themrmometer Hygrometer Living RoDigitales Themrmometer Hygrometer Living Ro",
  "Bulk Purchase": "Großeinkauf",
  "Amount": "Höhe",
  "Do you want to delete selected items?": "Möchten Sie ausgewählte Elemente löschen?",
  "商品列表页": "Produktlistenseite",
  "底部": "Unterseite",
  "顶部": "oben",
  "size":"Größe",
  "weight":"Gewicht",
  "Returnable with in":"Rückgabe innerhalb von",
  "Please input your message": "Bitte geben Sie Ihre Nachricht ein",
  "This file format is not supported": "Dieses Dateiformat wird nicht unterstützt",
  "The file size can not over 2MB": "Die Dateigröße darf 2 MB nicht überschreiten",
  "Hi, welcome to": "Hallo, herzlich willkommen",
  "Welcome to": "herzlich willkommen",
  "Please sign in or register an account to start the chat.": "Bitte melden Sie sich an oder registrieren Sie ein Konto, um den Chat zu starten.",
  "Welcome to ()! Please sign in or register an account to": "Willkommen bei ()! Melden Sie sich an oder registrieren Sie ein Konto bei",
  "start the chat.": "Starten Sie den Chat.",
  "Sign in": "Einloggen",
  "Go": "Weiter",
  "Register": "Registrieren",
  "Recall": "abrufen",
  "wallet": "Geldbörse",
  "balance": "Saldo",
  "system notification": "Systembenachrichtigung",
  "This month Orders": "Bestellungen in diesem Monat",
  "This month Revenue": "Einnahmen in diesem Monat",
  "Download tracking information": "Tracking-Informationen herunterladen",
  "This month Cost": "Kosten in diesem Monat",
  "Statistics": "Statistiken",
  "Reference": "Referenz",
  "Show More": "Zeig mehr",
  "Quick Summary": "Schnelle Zusammenfassung",
  "Total Revenue":"Gesamtumsatz",
  "Total Cost": "Gesamtkosten",
  "Revenue": "Einnahmen",
  "Cost": "Kosten",
  "Waiting to place order": "Warten auf Bestellung",
  "Waiting for tracking code": "Warten auf Tracking-Code",
  "Stores": "Shops",
  "Billing": "Rechnungsstellung",
  "Authorized": "Autorisiert",
  "Purchase price:": "Kaufpreis:",
  "Trade name": "Handelsname",
  "Choose a vendor": "Wählen Sie einen Anbieter",
  "no product": "kein Produkt",
  "Dispatched Items": "Versandte Artikel",
  "Valid Invoices": "Gültige Rechnungen",
  "Valid Amount Due": "Gültiger fälliger Betrag",
  "Order’s items that you can dispatch to vendors": "Artikel der Bestellung, die Sie an Lieferanten versenden können",
  "No Picture": "Kein Bild",
  "No Data": "Keine Daten",
  "Dispatch order’s items to vendors": "Artikel der Bestellung an Lieferanten versenden",
  "Your vendor": "Ihr Anbieter",
  "is inviting you to allocate your Orders": "lädt Sie ein, Ihre Bestellungen zuzuweisen",
  "Your store name": "Ihr Geschäftsname",
  "Dispatch order method": "Versandauftragsmethode",
  "All my unfulfilled / partially fulfilled orders": "Alle meine nicht erfüllten / teilweise erfüllten Bestellungen",
  "Cycle time": "Zykluszeit",
  "Every 1 day": "Jeden 1 Tag",
  "Order": "Bestellung",
  "Inquiry":"Anfrage",
  "You can modify your order dispatch rules at any time in the menu 'My Orders >> Dispatch settings'": "Sie können Ihre Versandregeln für Bestellungen jederzeit im Menü „'Meine Bestellungen >> Versandeinstellungen'",
  "Orders >> Dispatch settings": " Bestellungen >> Versandeinstellungen",
  "I agree": "Ich stimme zu",
  "According to your settings, we found some data for you": "Gemäß Ihren Einstellungen haben wir einige Daten für Sie gefunden",
  "Shopify order": "Shopify-Bestellung",
  "Dispatch to": "Versand nach",
  "Declare with my own IOSS": "Deklaration mit meinem eigenen IOSS",
  "vendor(s)": "Anbieter",
  "Dispatch in the Next Cycle": "Versand im nächsten Zyklus",
  "Dispatch Immediately": "Versand sofort",
  "You can modify your store settings at any time in the menu My Stores": "Sie können Ihre Shop-Einstellungen jederzeit im Menü „Meine Shops“ ändern.",
  "Remind later": "Erinnere mich später",
  "Newbie": "Neuling",
  "guide": "Führung",
  "you can click": "können Sie klicken",
  "for VAT information": "für Informationen zur Mehrwertsteuer",
  "VAT information": "Mehrwertsteuer-Informationen",
  "Complete  personal information": "Vollständige persönliche Daten",
  "Authorize to bind the store": "Ermächtigen Sie, den Laden zu binden",
  "Publish the push product": "Veröffentlichen Sie das Push-Produkt",
  "Start pulling   orders": "Fangen Sie an, Aufträge zu ziehen",
  "Already": "Bereits",
  "enter": "eintreten",
  "Please select the products": "Bitte wählen Sie die Produkte aus",
  "Please select the vendor": "Bitte wählen Sie den Anbieter aus",
  "Please select the Period": "Bitte wählen Sie den Zeitraum",
  "Please select the vendor visibility": "Bitte wählen Sie die Sichtbarkeit des Anbieters",
  "Please select the circle period": "Bitte wählen Sie den Kreiszeitraum aus",
  "Copy to clipboard failed, please copy manually": "Kopieren in die Zwischenablage fehlgeschlagen, bitte manuell kopieren",
  "Are you sure you want to cancel it?": "Möchten Sie es wirklich stornieren?",
  "Disputed order": "Umstrittener Auftrag",
  "Awaiting Response": "Bitte um Antwort",
  "Search": "Suche",
  "Tracking Number": "Verfolgungsnummer",
  "Dispute Type": "Streitfall",
  "Expected Solution": "Erwartete Lösung",
  "Last week": "Letzte Woche",
  "Last month": "Im vergangenen Monat",
  "Last three months": "Die letzten drei Monate",
  "The page you are looking for can't be found.": "Die gesuchte Seite kann nicht gefunden werden.",
  "BACK TO HOMEPAGE": "ZURÜCK ZUR STARTSEITE",
  "Transform Your eCommerce business Into a Powerhouse": "Verwandeln Sie Ihr E-Commerce-Geschäft in ein Kraftpaket",
  "Importify helps people like you to automate their dropshipping business.": "Importify hilft Menschen wie Ihnen, ihr Dropshipping-Geschäft zu automatisieren.",
  "Product Research": "Produktforschung",
  "Are you tired of searching the millions o products on AliExpress for potential winners?": "Sind Sie es leid, die Millionen von  Produkten auf AliExpress nach potenziellen Gewinnern zu suchen?",
  "Importify allows you to spy on other Shopify stores and find guaranteed winning products to sell in your niche.": " Importify ermöglicht es Ihnen, andere Shopify  Shops auszuspähen und garantiert erfolgreiche Produkte zu finden die Sie in Ihrer Nische verkaufen können.",
  "One-click import products": "Importieren Sie Produkte mit einem Klick",
  "Import products from 30+ top dropshipping vendors, Aliexpress, Alibaba, Amazon, Etsy, Dhgate, Lazada, Shopee, Banggood, Taobao, 1688, Gearbest, Walmart, and many more.": "Importieren Sie Produkte von über 30 Top-Dropshipping-Anbietern, Aliexpress, Alibaba, Amazon, Etsy, Dhgate, Lazada, Shopee, Banggood, Taobao, 1688, Gearbest, Walmart und vielen mehr.",
  "Pricing Rules": "Preisregeln",
  "Tired of calculating your target profit margin? Create pricing rules before importing, quickly adjust prices for sales.": "Sind Sie es leid, Ihre Zielgewinnmarge zu berechnen? Erstellen Sie Preisregeln vor dem Importieren und passen Sie die Preise für den Verkauf schnell an.",
  "Product Customization": "Produktanpassung",
  "Use our chrome extension to modify the title, description, images, variants, pricing of the imported products.": "Verwenden Sie unsere Chrome-Erweiterung, um Titel, Beschreibung, Bilder, Varianten und Preise der importierten Produkte zu ändern.",
  "Automate your orders": "Automatisieren Sie Ihre Bestellungen",
  "The Importify Chrome Extension allows you to fulfill your orders quickly by placing the customer address automatically on the checkout page.": "Mit der Importify Chrome Extension können Sie Ihre Bestellungen schnell ausführen, indem Sie die Kundenadresse automatisch auf der Checkout-Seite platzieren.",
  "Easy vendor Change": "Einfacher Anbieterwechsel",
  "Replace your product vendor with a new vendor and connect them to your store with a click.": "Ersetzen Sie Ihren Produktanbieter durch einen neuen Anbieter und verbinden Sie ihn mit einem Klick mit Ihrem Geschäft.",
  "Auto Dispatch Collections": "Sammlungen mit automatischem Versand",
  "Auto-dispatch products to your Store collections supported on Shopify, WooCommerce, and Jumpseller.": "Versenden Sie Produkte automatisch an Ihre Store-Sammlungen, die von Shopify, WooCommerce und Jumpseller unterstützt werden.",
  "Split Variants": "Split-Varianten",
  "Importify gives you the ability to Split variants into Separate products.": "Importify gibt Ihnen die Möglichkeit, Varianten in separate Produkte aufzuteilen.",
  "Connect Existing Products": "Vorhandene Produkte verbinden",
  "If you have products that are already on a Shopify, Woocommerce or Jumpseller store, you can connect and manage them in Importify.": "Wenn Sie Produkte haben, die sich bereits in einem Shopify-, Woocommerce- oder Jumpseller-Shop befinden, können Sie sie in Importify verbinden und verwalten.",
  "Add to Import List": "Zur Importliste hinzufügen",
  "Add to Cart": "In den Warenkorb",
  "There are no products in this category": "In dieser Kategorie gibt es keine Produkte",
  "View More": "Mehr sehen",
  "results": "Ergebnisse",
  "Connect": "Verbinden Sie",
  "Keywords": "Schlüsselwörter",
  "Find Products": "Produkte finden",
  "My Import List": "Meine Importliste",
  "You have successfully hosted the": "Sie haben erfolgreich die",
  "After the successful hosting, your supplier will take the initiative to quote for your pending orders!": "Nach dem erfolgreichen Hosting wird Ihr Lieferant die Initiative ergreifen und ein Angebot für Ihre anstehenden Aufträge abgeben!",
  "How to find products? Find products in": "So finden Sie Produkte Finden Sie Produkte in",
  "Are you sure you want to cancel the hosting store": "Sind Sie sicher, dass Sie den Hosting-Speicher kündigen möchten?",
  "after you close the hosting, the supplier will not be able to actively quote your order!": "Nachdem Sie das Hosting geschlossen haben, kann der Anbieter Ihre Bestellung nicht mehr aktiv anbieten!",
  "Products Market! Dropshipping from Worldwide to Worldwide! :": "Produktmarkt Dropshipping von weltweit nach weltweit! :",
  "Email must be filled in": "E-Mail muss ausgefüllt werden",
  "CAPTCHA must be filled in": "CAPTCHA muss ausgefüllt werden",
  "New password must be filled in": "Neues Passwort muss ausgefüllt werden",
  "The new password and confirm password must be the same": "Das neue Passwort und das Bestätigungspasswort müssen identisch sein",
  "The password must contain letters, and numbers, Minimum 8 characters": "Das Passwort muss Buchstaben und Zahlen enthalten, mindestens 8 Zeichen",
  "Password reset successful": "Zurücksetzen des Passworts erfolgreich",
  "Password must be filled in": "Passwort muss ausgefüllt werden",
  "Please enter Shopify store name": "Bitte geben Sie den Namen des Shopify-Shops ein",
  "Search products": "Produktsuche",
  "IN ALL CATEGORIES": "IN ALLEN KATEGORIEN",
  "Request Quotation": "Angebot anfordern",
  "No Account? ": "Kein Konto?",
  "Categories": "Kategorie",
  "More categories": "Mehr Kategorien",
  ">There are no products in this categor": ">Es gibt keine Produkte in dieser Kategorie",
  "Place Order": "Bestellung aufgeben",
  "Manage-Addresses": "Manage-Adressen",
  "Default Address": "Standardadresse",
  "There is no Shipping method to reach the current address": "Es gibt keine Versandmethode, um die aktuelle Adresse zu erreichen",
  "Please select address": "Bitte Adresse auswählen",
  "Order Information": "Bestellinformationen",
  "Product Price": "Produktpreis",
  "Product information": "Produktinformation",
  "Choose the products from your Shopify orders to allocated to your vendor": "Wählen Sie die Produkte aus Ihren Shopify-Bestellungen aus, die Sie Ihrem Anbieter zuweisen möchten",
  "Click the Allocate to vendo button on the bottom of the page.": "Klicken Sie unten auf der Seite auf die Schaltfläche Dem Anbieter zuweisen.",
  "Tips: You can filter your orders here.": "Tipps: Hier können Sie Ihre Bestellungen filtern.",
  "Choose or create a new vendor.": "Wählen Sie einen neuen Anbieter aus oder erstellen Sie einen neuen.",
  "Click the Allocate button of the page,you can also allocate items from the same SPU to the same vendor": "Klicken Sie auf die Schaltfläche „Zuordnen“ auf der Seite. Sie können auch Artikel derselben SPU demselben Lieferanten zuweisen",
  "Un-allocate selected products": "Zuweisung ausgewählter Produkte aufheben",
  "Total vendors": "Anbieter insgesamt",
  "You can create a new vendor or select an exist one.": "Sie können einen neuen Lieferanten anlegen oder einen bestehenden auswählen.",
  "Total orders": "Bestellungen insgesamt",
  "Place 1 purchase orders": "Geben Sie 1 Bestellungen auf",
  "Create a purchase orders to your vendor.": "Erstellen Sie eine Bestellung an Ihren Lieferanten.",
  "Choose the Shopify orders you want to sourcing,and click the Make a purchase order button of the page.": "Wählen Sie die Shopify-Bestellungen aus, die Sie beschaffen möchten, und klicken Sie auf der Seite auf die Schaltfläche Bestellung aufgeben.",
  "After confirming the operation,The purchase orders will be created successfully.": "Nach Bestätigung des Vorgangs werden die Bestellungen erfolgreich erstellt.",
  "Share purchase orders URL to your vendor and wait your vendor finish the invoice for you.": "Geben Sie die Bestell-URL an Ihren Lieferanten weiter und warten Sie, bis Ihr Lieferant die Rechnung für Sie fertig gestellt hat.",
  "Click the <b>Invite vendor to process</b> button on the bottom of the page.": "Klicken Sie unten auf der Seite auf die Schaltfläche <b>Anbieter zur Bearbeitung einladen</b>.",
  "You can send the purchase orders by Skype, WeChat or share by URL link.": "Sie können die Bestellungen per Skype, WeChat senden oder per URL-Link teilen.",
  "I have finished the payment": "Ich habe die Zahlung abgeschlossen",
  "Items total": "Artikel insgesamt",
  "Shipping costs": "Versandkosten",
  "Amount Due": "Offener Betrag",
  "Previous": "Vorherige",
  "<b>Invite vendor to process</b>": "<b>Invite vendor to process</b>",
  "Payment method": "Zahlungsmethode",
  "Through AliExpress proxy payment link": "Über AliExpress-Proxy-Zahlungslink",
  "Invite vendor to proess purchase order": "Laden Sie den Lieferanten ein, die Bestellung zu bearbeiten",
  "Click the button": "Klicken Sie auf die Schaltfläche",
  "on the PO page when you finished your payment.": "auf der Bestellseite, wenn Sie Ihre Zahlung abgeschlossen haben.",
  "After you receive the vendor's invoice and finished the payment.": "Nachdem Sie die Rechnung des Lieferanten erhalten und die Zahlung abgeschlossen haben.",
  "After vendor shipped, Shopify order will be fulfilled automatically.": "Nach dem Versand durch den Verkäufer wird die Shopify-Bestellung automatisch ausgeführt.",
  "After your vendor shipped your order, the shipping information will be sync to your Shopify store automatically.": "Nachdem Ihr Anbieter Ihre Bestellung versandt hat, werden die Versandinformationen automatisch mit Ihrem Shopify-Shop synchronisiert.",
  "Billing History": "Rechnungsverlauf",
  "Search by bill number or order number": "Suche nach Rechnungsnummer oder Bestellnummer",
  "Order Code": "Bestellcode",
  "Request for Quote": "Anfrage für ein Angebot",
  "Wait for import": "Warten Sie auf den Import",
  "My Profile": "Mein Profil",
  "Allocate Orders for vendors": "Weisen Sie Bestellungen für Lieferanten zu",
  "and Automate": "und Automatisieren Sie",
  "your entire dropshipping process": "Ihren gesamten Dropshipping-Prozess",
  "With just one click, you can easily allocate your stores orders to your vendors and the entire shipping process will be automatically completed.": "Mit nur einem Klick können Sie Ihre Filialbestellungen Ihren Lieferanten zuordnen und der gesamte Versandprozess wird automatisch abgeschlossen.",
  "How does it work?": "Wie funktioniert es?",
  "Connect to your store": "Verbinde dich mit deinem Shop",
  "Allocate orders to your vendors": "Weisen Sie Bestellungen Ihren Lieferanten zu",
  "The vendor arranges the delivery": "Der Anbieter organisiert die Lieferung",
  "Automatic fulfillment of your orders by": "Automatische Erfüllung Ihrer Bestellungen durch",
  "See what our": "Sehen Sie, was unsere",
  "Please enter your shopify store name to connect the dashboard page": "Bitte geben Sie den Namen Ihres Shopify-Shops ein, um die Dashboard-Seite zu öffnen",
  "customers talk about us.": "Kunden sprechen über uns.",
  "More than 10,000+ customers around the world rely on us!": "Mehr als 10.000+ Kunden auf der ganzen Welt verlassen sich auf uns!",
  "You can import and manage your AliExpress / Amazon products here": "Hier können Sie Ihre AliExpress-/Amazon-Produkte importieren und verwalten",
  "before publishing them to your Shopify store. You can publish a": " bevor Sie sie in Ihrem Shopify-Shop veröffentlichen. Sie können a",
  "product to different stores and add multiple tags to each products.": " Produkt in verschiedenen Shops und fügen Sie jedem Produkt mehrere Tags hinzu.",
  "Click": "Klicken",
  "here": "hier",
  "to leam more": "mehr zu lernen",
  "Import Produc": "Produkte importieren",
  "Import Task": "Import Task",
  "Current Import Task": "Aktuelle Importaufgabe",
  "Add Tags": "Importaufgabe",
  "Tag management": "Tag-Management",
  "Filter by stores": "Nach Filialen filtern",
  "Filter by tags": "Filtern Sie nach Tags",
  "Compare Price": "Preis vergleichen",
  "Select an image": "Bild auswählen",
  "Push setting": "Push-Einstellungen",
  "Rest of the price ranges": "Rest der Preisklassen",
  "You can set a specific cent value for your price or comparison": "Sie können einen bestimmten Cent-Wert für Ihren Preis oder Vergleich festlegen",
  "price.": "Preis.",
  "Product Cost": "Produktkosten",
  "ID Range": "ID-Bereich",
  "Assign Cent": "Cent zuweisen",
  "Also publish to Online Store": "Auch im Online Store veröffentlichen",
  "Loading time to long, check detailed progress": "Ladezeit zu lang, detaillierten Fortschritt prüfen",
  "Delete Completed": "Löschen abgeschlossen",
  "Please select the stores": "Bitte wählen Sie die Filialen aus",
  "Please enter the New tag": "Bitte geben Sie das neue Tag ein",
  "The update  has started, it will take a while to finish.": "Das Update hat begonnen, es wird eine Weile dauern, bis es fertig ist.",
  "Do you want to delete the ${} product(s)?": "Möchten Sie die Produkte von ${} löschen?",
  "Import success": "Erfolg importieren",
  "Order ID To:":"Bestell-ID bis:",
  "Allow vendor to process these orders": "Erlauben Sie dem Lieferanten, diese Bestellungen zu verarbeiten",
  "Please enter your store name to connect the dashboard page:": "Bitte geben Sie Ihren Shop-Namen ein, um die Dashboard-Seite zu verbinden:",
  "Coming soon": "Kommt bald",
  "Qty Available": "Warenbestand",
  "Login to your": "Anmelden bei Ihrem",
  "Unit Price": "Preis",
  "First Available": "Verfügbarkeit",
  "Sort by": "Sortiert",
  "published": "veröffentlicht",
  "vendor is authorizing the store on Ourmall Dropshipping App, please be noted.": "Der Verkäufer autorisiert das Geschäft auf Ourmall Dropshipping App, bitte beachten Sie.",
  "Assign orders to vender": "Weisen Sie Bestellungen einem Lieferanten zu",
  "Step One: Select not shipped orders": "Schritt Eins: Wählen Sie nicht versandte Bestellungen aus",
  "Step Two: Confirm and send these orders to the vendor": "Schritt Zwei: Bestätigen und senden Sie diese Bestellungen an den Lieferanten",
  "Choose one vendor": "Wählen Sie einen Anbieter aus",
  "Product Name": "Produktname",
  "Abort to assign": "Zum Zuweisen abbrechen",
  "Next": "Weiter",
  "Unit Cost": "Stückkosten",
  "Product SKU": "Produkt SKU",
  "Go to Next": "Gehen Sie zu Weiter",
  "Confirm and send these orders to this vendor": "Bestätigen und senden Sie diese Bestellungen an diesen Lieferanten",
  "You can check the invoice by scan this QR Code or visit this URL by your web browser": "Sie können die Rechnung überprüfen, indem Sie diesen QR-Code scannen oder diese URL mit Ihrem Webbrowser aufrufen",
  "Time Created": "  Zeit erstellt",
  "Receiver": "Empfänger",
  "Company": "Unternehmen",
  "Save and change the vendor": "Speichern und ändern Sie den Lieferanten",
  "Selected saved": "Ausgewählt gespeichert",
  "Now you can": "Jetzt kannst du",
  "Send purchase orders to the vendor": "Senden Sie Bestellungen an den Lieferanten",
  "Check the list again": "Überprüfen Sie die Liste erneut",
  "All Unfulfilled Shopify Orders": "Alle unerfüllten Shopify-Bestellungen",
  "Quick select of Shopify order items": "Schnellauswahl von Shopify-Bestellartikeln",
  "Shopify order total": "Shopify-Bestellung insgesamt",
  "Order Date": "Bestelldatum",
  "Periods of orders:": "Bestellfristen:",
  "shopify orders have been selected and can now be dispatched to your vendor": "Shopify-Bestellungen wurden ausgewählt und können nun an Ihren Anbieter versandt werden",
  "Dispatch to the vendor": "Versand an den Anbieter",
  "Please choose an vendor": "Bitte wählen Sie einen Anbieter aus",
  "Please choose an item": "Bitte wählen Sie einen Artikel aus",
  "A new order successfully created": "Eine neue Bestellung wurde erfolgreich erstellt",
  "All shopify unshipped orders": "Alle unversendeten Shopify-Bestellungen",
  "All undelivered shopify orders": "Alle nicht gelieferten Shopify-Bestellungen",
  "shopify orders have been selected and can now be assigned to your suppliers": "Shopify-Bestellungen wurden ausgewählt und können nun Ihren Lieferanten zugewiesen werden",
  "customers successfully added": "Kunden erfolgreich hinzugefügt",
  "Payment Record": "Zahlungs Aufzeichnung",
  "Recharge": "Aufladen",
  "Payment Record": "Zahlungs Aufzeichnung",
  "trade code": "Handelscode",
  "Payment Amount": "Zahlungsbetrag",
  "Payment Status": "Zahlungsstatus",
  "Payment Number": "Zahlungsnummer",
  "Payment Date": "Zahlungsdatum",
  "You have authorized a Shopify store，you could": "Sie haben einen Shopify-Shop autorisiert, Sie könnten",
  "authorize to login": "zum Login autorisieren",
  "to view details。": "Details anzuzeigen.",
  "If it is necessary to process your shopify order or to achieve an automatically update for the shipping information toshopify, you could": "Wenn es notwendig ist, Ihre Shopify-Bestellung zu bearbeiten oder eine automatische Aktualisierung der Versandinformationen für Shopify zu erreichen, können Sie dies tun",
  "connect your Shopify store": "Verbinden Sie Ihren Shopify-Shop",
  "and let us help you.": "und lassen Sie sich von uns helfen.",
  "If you have authorized a Shopify store, after the vendor delivers the items, the shipping information will been synchronized to your Shopify store which you had authorized.": "Wenn Sie einen Shopify-Shop autorisiert haben, wurden die Versandinformationen nach Lieferung der Artikel durch den Verkäufer mit Ihrem von Ihnen autorisierten Shopify-Shop synchronisiert.",
  "(If there are some Shopify stores in the order list below that  have not been authorized, you can": "(Wenn es einige Shopify-Shops in der Bestellliste unten gibt, die nicht autorisiert wurden, können Sie dies tun",
  "authorize a new one": "einen neuen autorisieren",
  "If you need to view your recent orders, please click": "Wenn Sie Ihre letzten Bestellungen anzeigen möchten, klicken Sie bitte",
  "My to view details.": "Mein, um Details anzuzeigen.",
  "Cancelled": "abgebrochen",
  "Purchase Order": "Auftragsbestätigung",
  "The vendor has shipped the product(s), but the shipping information has not been sync to your  Shopify store.": "Der Anbieter hat die Produkte versendet, aber die Versandinformationen wurden nicht mit Ihrem Shopify-Shop synchronisiert.",
  "Will not ship": "Wird nicht versendet",
  "Not shipped": "Nicht ausgeliefert",
  "Quantity": "Menge",
  "Subtotal": "Zwischensumme",
  "Logistics fee": "Logistikgebühr",
  "The webmaster has confirmed the   payment": "Der Webmaster hat die Zahlung bestätigt",
  "The vendor has confirmed the payment": "Der Verkäufer hat die Zahlung bestätigt",
  "The vendor start delivery the orders": "Der Lieferant beginnt mit der Auslieferung der Bestellungen",
  "Shipping costs ": "Versandkosten",
  "Discount": "Rabatt",
  "Amount Due": "Offener Betrag",
  "Via paypal": "Über PayPal",
  "Through a bank transfer": "Durch eine Banküberweisung",
  "According to the vendor’s setting you can go to": "Je nach Einstellung des Anbieters können Sie zu gehen",
  "Aliexpress to buy the item in the vendor’s store": "Aliexpress, um den Artikel im Geschäft des Verkäufers zu kaufen",
  "Go to AliExpress NOW": "Gehen Sie JETZT zu AliExpress",
  "Invite vendor to process purchase order": "Laden Sie den Lieferanten ein, die Bestellung zu bearbeiten",
  "webmaster has confirmed the payment": "Webmaster hat die Zahlung bestätigt",
  "The vendor has confirmed the payment": "Der Verkäufer hat die Zahlung bestätigt",
  "The vendor start delivery the orders": "Der Lieferant beginnt mit der Auslieferung der Bestellungen",
  "The webmaster has confirmed the payment": "Der Webmaster hat die Zahlung bestätigt",
  "The vendor has confirmed the payment": "Der Verkäufer hat die Zahlung bestätigt",
  "The vendor start delivery the orders": "Der Lieferant beginnt mit der Auslieferung der Bestellungen",
  "According to the vendor’s setting you can go to": "Je nach Einstellung des Anbieters können Sie zu gehen",
  "Aliexpress to buy the item in the vendor’s store": "Aliexpress, um den Artikel im Geschäft des Verkäufers zu kaufen",
  "Go to AliExpress NOW": "Gehen Sie JETZT zu AliExpress",
  "Invite vendor": "Laden Sie",
  "You can download order's shipping information here when I arranges the delivery.": "Sie können die Versandinformationen der Bestellung hier herunterladen, wenn ich die Lieferung arrangiere.",
  "Timeline": "Zeitleiste",
  "No more comment": "Keine weiteren Kommentare",
  "You have connected a Shopify store before, for your data security,  please": "Sie haben zu Ihrer Datensicherheit zuvor einen Shopify-Shop verbunden",
  "log in the Shopify account": "Melden Sie sich beim Shopify-Konto an",
  "you connected and view this invoice's details.": "Sie verbunden sind und die Details dieser Rechnung anzeigen.",
  "当前发票页面仅供预览，如果发送给客户请使用发票编辑页面的“发送发票”功能！": "Die aktuelle Rechnungsseite dient nur zur Vorschau, wenn Sie diese an den Kunden versenden möchten, nutzen Sie bitte die Funktion Rechnung senden auf der Rechnungsbearbeitungsseite!",
  "发票预览": "Rechnungsvorschau",
  "如图一所示:页面顶部“发送发票”按钮": "Wie in Abbildung 1 gezeigt: Schaltfläche „Rechnung senden“ oben auf der Seite",
  "如图二所示:页面底部“发送发票”按钮": "Wie in Abbildung 2 gezeigt: Schaltfläche „Rechnung senden“ unten auf der Seite",
  "I knew it": "Ich wusste es",
  "Connect your Shopify store": "Verbinden Sie Ihren Shopify-Shop",
  "you can dispatch the Shopify orders to your vendors after the authorization and the shipping information will be automatically synchronized to your Shopify Store.": "Sie können die Shopify-Bestellungen nach der Autorisierung an Ihre Lieferanten versenden und die Versandinformationen werden automatisch mit Ihrem Shopify-Shop synchronisiert.",
  "Enter your existing Shopify URL bellow and click  “connect”, you will be redirect to Shopify to connect your account to (the authorization will be also completed).": "Geben Sie unten Ihre vorhandene Shopify-URL ein und klicken Sie auf „Verbinden“. Sie werden zu Shopify weitergeleitet, um Ihr Konto zu verbinden (die Autorisierung wird ebenfalls abgeschlossen).",
  "Connect your Shopify store": "Verbinden Sie Ihren Shopify-Shop",
  "Connect a store and fulfill your Shopify orders, sync logistics information to your Shopify store automatically.": "Verbinden Sie einen Shop und führen Sie Ihre Shopify-Bestellungen aus, synchronisieren Sie Logistikinformationen automatisch mit Ihrem Shopify-Shop.",
  "Enter your existing Shopify store URL bellow and you'll be  redirect to Shopify to connect your account to": "Geben Sie unten Ihre bestehende Shopify-Shop-URL ein und Sie werden zu Shopify weitergeleitet, um Ihr Konto damit zu verbinden",
  "The Shopify orders still unfulfilled / partially fulfilled even if the vendor had upload tracking information.": "Die Shopify-Bestellungen sind immer noch unerfüllt/teilweise erfüllt, auch wenn der Verkäufer Tracking-Informationen hochgeladen hatte.",
  "采购订单基础信息": "Grundlegende Informationen zur Bestellung",
  "You may download these tracking information and fulfilled these order in your Shopify store manually.":"Sie können diese Tracking-Informationen herunterladen und diese Bestellung in Ihrem Shopify-Shop manuell ausführen.",
  "订单总数": "Gesamtbestellung",
  "订单商品项": "Einzelposten",
  "合计商品总数": "Gesamtzahl der Artikel",
  "合计采购金额": "Gesamtkaufbetrag",
  "发货情况统计": "Versandstatistik",
  "已发货商品数": "Artikel versendet",
  "已发货商品额": "Artikel versendet",
  "未发货商品数": "Nicht versendete Artikel",
  "未发货商品额": "Anzahl unversendeter Artikel",
  "发货率": "Versandrate",
  "不发货商品数": "Artikel nicht versendet",
  "不发货商品额": "Menge der nicht versendeten Ware",
  "Set the vendor’s SKU": "Legen Sie die SKU des Anbieters fest",
  "Set the supply price": "Stellen Sie den Angebotspreis ein",
  "Set up payment methods": "Zahlungsmethoden einrichten",
  "Payment Methods": "Zahlungsarten",
  "Send the invoice to the customer": "Senden Sie die Rechnung an den Kunden",
  "The fields to be exported": "Die zu exportierenden Felder",
  "the sequence is adjustable by dragging forward and backward": "die Reihenfolge ist durch Vor- und Zurückziehen einstellbar",
  "您还没有选择可导出的字段。请在下方选择。": "Sie haben keine zu exportierenden Felder ausgewählt. Bitte wählen Sie unten aus.",
  "optional fields": "optionale Felder",
  "unexported fields": "nicht exportierte Felder",
  "custom field name": "benutzerdefinierter Feldname",
  "is displayed as": "wird angezeigt als",
  "Export order's information to CSV": "Bestellinformationen in CSV exportieren",
  "BILL To": "Gesetzesentwurf für",
  "Invoice Number": "Rechnungsnummer",
  "Invoice Date": "Rechnungsdatum",
  "Track Number": "Titelnummer",
  "Shopify order number":"Shopify-Bestellnummer",
  "Shopify store Name": "Shopify-Shop Name",
  "Edit Product": "Produkt bearbeiten",
  "Amount Due (US$)": "Fälliger Betrag (US$)",
  "供应商已发货，但物流信息未同步到Shopify店铺。": "Der Lieferant hat versendet, aber die Logistikinformationen wurden nicht mit dem Shopify-Shop synchronisiert.",
  "Reason": "Grund",
  "供应商已发货，物流信息已同步到Shopify店铺。": "Der Lieferant hat die Waren versendet und die Logistikinformationen wurden mit dem Shopify-Shop synchronisiert.",
  "Mark as shipped": "Als verschickt markieren",
  "The webmaster has confirmed the payment": "Der Webmaster hat die Zahlung bestätigt",
  "The vendor has confirmed the payment": "Der Verkäufer hat die Zahlung bestätigt",
  "According to the vendor’s setting you can go to": "Je nach Einstellung des Anbieters können Sie zu gehen",
  "Aliexpress to buy the item in the vendor’s store": "Aliexpress, um den Artikel im Geschäft des Verkäufers zu kaufen",
  "to finish the payment": "um die Zahlung abzuschließen",
  "Go to AliExpress NOW": "Gehen Sie JETZT zu AliExpress",
  "The vender has confirmed the payment.": "Der Verkäufer hat die Zahlung bestätigt.",
  "Remove from order": "Von der Bestellung entfernen",
  "You are deleting this product from purchase order. You can still find it": "Sie löschen dieses Produkt aus der Bestellung. Sie können es immer noch finden",
  "in your Waiting for sourcing menu.": "in Ihrem Menü Warten auf Beschaffung.",
  "Order Number": "Bestellnummer",
  "Total Amount:": "Gesamtmenge:",
  'Total Payment': 'Gesamtzahlung',
  "Pay with": "Bezahlen mit",
  "Please do not close the page during payment": "Bitte schließen Sie die Seite nicht während der Zahlung",
  "Your order has been successfully paid and the order amount is": "Ihre Bestellung wurde erfolgreich bezahlt und der Bestellbetrag ist",
  "Close Pay": "Bezahlen schließen",
  "Remove success": "Erfolg entfernen",
  "Track number must be entered": "Titelnummer muss eingegeben werden",
  "Track company must be entered": "Gleisfirma muss eingegeben werden",
  "Track company  must be entered": "Gleisfirma muss eingegeben werden",
  "Track Url must be entered": "Track-URL muss eingegeben werden",
  "Please enter Shopify store URL": "Bitte geben Sie die URL des Shopify-Shops ein",
  "Under authorization, please wait…": "Unter Autorisierung, bitte warten…",
  "After your confirmation for the payment, we will notify the vendor by email. You can also proactively notify the vendor to speed the delivery up": "Nachdem Sie die Zahlung bestätigt haben, benachrichtigen wir den Verkäufer per E-Mail. Sie können den Verkäufer auch proaktiv benachrichtigen, um die Lieferung zu beschleunigen",
  "Have you finished the payment": "Haben Sie die Zahlung abgeschlossen?",
  "At least one field must be selected": "Es muss mindestens ein Feld ausgewählt werden",
  "The name of the customized header cannot be empty": "Der Name des benutzerdefinierten Headers darf nicht leer sein",
  "Export the order information": "Bestellinformationen exportieren",
  "上传图片大小不能超过 500kb!": "Die hochgeladene Bildgröße darf 500kb nicht überschreiten!",
  "Simple, Transparent Pricing": "Einfache, transparente Preisgestaltung",
  "mportify offers three types of plans; Basic plan, Premium plan, and Gold plan.": "mportify bietet drei Arten von Plänen an: Basisplan, Premiumplan und Goldplan.",
  "Each account starts with a day trial, and your billing cycle begins after the free trial period has ended.": "Jedes Konto beginnt mit einer eintägigen Testphase, und Ihr Abrechnungszeitraum beginnt nach Ablauf der kostenlosen Testphase.",
  "Importify Plans<": "Pläne importieren<",
  "Basics": "Grundlagen",
  "Premium": "Prämie",
  "Gold": "Gold",
  "MORE": "MEHR",
  "Amazon & Aliexpress importer": "Amazon- und Aliexpress-Importeur",
  "Semi-automatic order fulfillment (Supported on Aliexpress, Amazon, Dhgate & Wonify)": "Halbautomatische Auftragserfüllung (unterstützt von Aliexpress, Amazon, Dhgate & Wonify)",
  "Product Customisation": "Produktanpassung",
  "描述文字": "Beschreibungstext",
  "The tax-inclusive price of an item is calculated based on the item price set by the seller and the applicable tax rate. You can contact the seller for more details.": "Der Preis eines Artikels inklusive MwSt. wird auf der Grundlage des vom Verkäufer festgelegten Artikelpreises und des anwendbaren Steuersatzes berechnet. Für weitere Einzelheiten können Sie sich an den Verkäufer wenden.",
  "VAT rate(Destination only in Germany)": "MwSt.-Satz ( Lieferung in Deutschland)",
  "Shipping options": "Versandoptionen",
  "履行费用": "Erfüllungskosten",
  "Fulfillment Costs": "Erfüllungskosten",
  "发货时效": "Lieferdauer",
  "Excluding VAT amount": "Preis zzgl. MwSt",
  "VAT am": "MwSt.-Betrag",
  "Amount including VAT": "Inkl.MwSt.",
  "Shipping Time": "Lieferdauer",
  "After Sales Service": "Kundendienst",
  "product description": "Beschreibung",
  "Download the attachment": "Laden Sie den Anhang herunter",
  "product information": "Produktinformation",
  "Product category": "Produktkategorie",
  "Specification": "Spezifikation",
  "material": "Material",
  "Delivery:   Varies for items shipped from an international location": "Lieferung: Variiert für Artikel, die von einem internationalen Standort versendet werden",
  "This product has been removed from the shelves": "Dieses Produkt wurde aus den Regalen entfernt",
  "Return": "Zurückkehren",
  "Enter Store name": "Geben Sie den Namen des Geschäfts ein",
  "Enter vendor name": "Geben Sie den Lieferantennamen ein",
  "Open in  website": "Auf der Website öffnen",
  "Retail price:": "Endverbraucherpreis:",
  "NOT SET": "NICHT FESTGELEGT",
  "Supplier price": "Lieferantenpreis",
  "GENERAL INFORMATION": "ALLGEMEINE INFORMATIONEN",
  "CONTACT": "KONTAKT",
  "SOCIAL": "SOZIAL",
  "Notification Settings": "Benachrichtigungseinstellungen",
  "I want to receive notfications by": "Ich möchte Benachrichtigungen per erhalten",
  "Mobile phone": "Handy",
  "Email": "Email",
  "Reset All": "Alles zurücksetzen",
  "Allow system notifhcations": "Systembenachrichtigungen zulassen",
  "Allow Customer Services from ": "Kundendienst von ",
  "Allow Dropshipping News": "Dropshipping-Nachrichten zulassen",
  "Save Changes": "Änderungen speichern",
  "Complete your Skype or WhatsApp details to get rewards": "Vervollständigen Sie Ihre Skype- oder WhatsApp-Details, um Prämien zu erhalten",
  "You have completed the Skype or WhatsApp, you can receive a free gift package.": "Haben Sie Skype oder WhatsApp abgeschlossen, können Sie ein kostenloses Geschenkpaket erhalten.",
  "The captcha was sent successfully": "Das Captcha wurde erfolgreich versendet",
  "Order Details": "Bestelldetails",
  "Sub Order NO": "Unterauftrag Nr",
  "Status": "Status",
  "Product Information": "Produktinformation",
  "Quantity": "Menge",
  "Sub Total": "Zwischensumme",
  "Grand Total": "Gesamtsumme",
  "Address": "die Anschrift",
  "Total Amount": "Gesamtmenge",
  "Products Cost": "Produktkosten",
  "Weight": "Gewicht",
  "Purchase List": "Einkaufsliste",
  "Shipping": "Versand",
  "Enter SKU": "Geben Sie SKUs ein",
  "Shipping to": "Versand an",
  "Add to List": "Merkzettel",
  "Push Setting": "Push-Einstellung",
  "Shipping fee": "Versandgebühr",
  "Package": "Paket",
  "Push":"Schieben Sie",
  "I agree the": "Ich stimme dem zu",
  "Shipping Cost Included": "Versandkosten inbegriffen",
  "Do you want to cancel the order?": "„Möchtest du die Bestellung stornieren?",
  "Order was successfully cancelled": "Bestellung wurde erfolgreich storniert",
  "Choose your recharge package": "Wählen Sie Ihr Aufladepaket",
  "Choose your payment method": "Wähle deine Zahlungsmethode",
  "Payment Record": "Zahlungs Aufzeichnung",
  "Terms of use": "Nutzungsbedingungen",
  "Refund Policy": "Rückgaberecht",
  "Pay NOW": "Jetzt bezahlen",
  "History": "Geschichte",
  "Picture": "Bild",
  "orders NOW": "JETZT bestellen",
  "Place": "Ort",
  "You can only upload 5 pictures": "Sie können nur 5 Bilder hochladen",
  "Product Title": "Produktname:",
  "Price Range": "Preisklasse:",
  "Expected Price": "Erwarteter Preis",
  "Product Links": "Produktlinks:",
  "Product link": "Produkt-Link",
  "Product Description": "Produktbeschreibung:",
  "Publish": "Veröffentlichen",
  "Trade Name": "Handelsname",
  "All vendors": "Alle Anbieter",
  "Dispatch Status": "Versandstatus",
  "You could select multiple vendors": "Sie können mehrere Anbieter auswählen",
  "Please enter price": "Bitte geben Sie den Preis ein",
  "Automatic dispatch orders setting": "Einstellung für automatische Versandaufträge",
  "Show hidden orders": "Ausgeblendete Bestellungen anzeigen",
  "Show all valid orders": "Alle gültigen Bestellungen anzeigen",
  "shopify orders have been selected and can now be dispatched to your vendors": "Shopify-Bestellungen wurden ausgewählt und können nun an Ihre Lieferanten versendet werden",
  "Dispatch to the vendor": "Versand an den Anbieter",
  "My all Shopify products": "Meine alle Shopify-Produkte",
  "days unfulfilled orders": "Tage unerfüllte Bestellungen",
  "Dispatch rule TEST": "Versandregel TEST",
  "Only matching last": 'Nur passende Leisten',
  "THE BRIDGE CONNECTING SUPPLIERS AND YOU, MAKING DROPSHIPPING BUSINESS MORE CONVENIENT": "DIE BRÜCKE, DIE LIEFERANTEN UND SIE VERBINDET UND DAS DROPSHIPPING-GESCHÄFT BEQUEMER MACHT",
  "Your supplier only needs to register an account to quickly build the system": "Ihr Lieferant muss nur ein Konto registrieren, um das System schnell aufzubauen",
  "One-click hosting of stores, fast synchronization of orders": "Ein-Klick-Hosting von Shops, schnelle Synchronisierung von Bestellungen",
  "Automatically synchronize logistics information": "Logistikinformationen automatisch synchronisieren",
  "Quick Access": "Schneller Zugang",
  "Do you have an OurMall supplier for you": "Haben Sie einen OurMall-Lieferanten für Sie?",
  "Already have a supplier": "Sie haben bereits einen Lieferanten",
  "I don't have a supplier": "Ich habe keinen Lieferanten",
  "Let your supplier fulfill your order more systematically, copy the link and send it to your supplier, and immediately invite your supplier to settle in,": "Lassen Sie Ihren Lieferanten Ihre Bestellung systematischer erfüllen, kopieren Sie den Link und senden Sie ihn an Ihren Lieferanten und laden Sie Ihren Lieferanten sofort ein, sich niederzulassen,",
  "2015-2021OurMall All Rights Reserved": "2015-2023OurMall Alle Rechte vorbehalten",
  "Waiting for the payment result to be returned": "Warten auf die Rückgabe des Zahlungsergebnisses",
  "You may need to wait a little while": "Möglicherweise müssen Sie eine Weile warten",
  "View order": "Bestellung ansehen",
  "You have successfully completed your order": "Sie haben Ihre Bestellung erfolgreich abgeschlossen",
  "Payment failed": "Bezahlung fehlgeschlagen",
  "Sorry, we can't complete the payment": "Wir können die Zahlung leider nicht abschließen",
  "Product Details": "Produktdetails",
  "Please check the purchase products": "Bitte überprüfen Sie die Kaufprodukte",
  "out of stock": "nicht vorrättig",
  "Credits": "Kredite",
  "Operate": "Betreiben Sie",
  "Amount paid/Credit payment":"Gezahlter Betrag/Kreditzahlung",
  "Amount due (including freight)": "Fälliger Betrag (einschließlich Fracht)",
  "Submit orders": "Bestellungen einreichen",
  "Please select the goods first!": "Bitte wählen Sie zuerst die Ware aus!",
  "Please choose the logistics channel": "Bitte wählen Sie den Logistikkanal",
  "Please select payment method": "Bitte Zahlungsmethode auswählen",
  "请稍后重试～": "Bitte versuchen Sie es später noch einmal~",
  "Wholesale order": "Großhandelsbestellung",
  "offline order": "Offline-Bestellung",
  "Shipping Address": "Lieferanschrift",
  "User Name": "Nutzername",
  "Users login": "Benutzer anmelden",
  "Password": "Passwort",
  "Add a new store": "Fügen Sie einen neuen Shop hinzu",
  "Auth Status": "Auth-Status",
  "All Status": "Alle Status",
  "Authorized": "Autorisiert",
  "Unauthorized": "Unbefugt",
  "Creation date": "Erstellungsdatum",
  "Auth date:": "Authentifizierungsdatum:",
  "Remove entrusted vendor": "Beauftragten Lieferanten entfernen",
  "Reauthorize": "Neu autorisieren",
  "no product": "kein Produkt",
  "Delete Task": "Aufgabe löschen",
  "Select the task": "Wählen Sie die Aufgabe aus",
  "Do you want to delete this task?": "Möchten Sie diese Aufgabe löschen?",
  "Create a ticket": "Ticket erstellen",
  "Request": "Anfrage",
  "My support": "Meine Unterstützung",
  "Store name:": "Geschäftsname:",
  "Store Name": "Name des Shops",
  "No Message": "Keine Nachricht",
  "All Action": "Alle Aktionen",
  "Send": "senden.",
  "Enter Verification Code": "Geben Sie den Verifizierungscode ein",
  "Tell us more about your request": "Erzählen Sie uns mehr über Ihr Anliegen",
  "What is your Shopify store name?": "Wie lautet der Name Ihres Shopify-Shops?",
  "What is your store name?": "Wie lautet der Name Ihres Geschäfts?",
  "What is your Email?": "Wie lautet Ihre E-Mail?",
  "Transaction": "Transaktion",
  "Shopify store name": "Name des Shopify-Ladens",
  "normal bill": "normale Rechnung",
  "abnormal bill":"Abnormale Rechnung",
  "Create Date": "Erstellungsdatum",
  "Shopify store": "Shopify-Store",
  "Sale price": "Verkaufspreis",
  "Order number": "Auftragsnummer",
  "Third Party Number": "Drittanbieternummer",
  "The export of time": "Der Export der Zeit",
  "Petitioner：": "Antragsteller:",
  "My Allocated Products": "My Allocated Products",
  "Payment code": "Zahlungscode",
  "Trade type": "Handelstyp",
  "invalid":"ungültig",
  "Account payee": "Konto Zahlungsempfänger",
  "Income": "Einkommen",
  "Trade status": "Transaktionsstatus",
  "Payment type":"Zahlungsart",
  "Income will only visible to you": "Die Einnahmen sind nur für Sie sichtbar",
  "Trade amount": "Handelsbetrag",
  "Payment order": "Zahlungsauftrag",
  "Refund order": "Bestellung erstatten",
  "Wallet card": "Wallet-Karte",
  "Manual deduction": "Manueller Abzug",
  "Paying": "Bezahlen",
  "Only orders in": "Nur Bestellungen in",
  "Paid": "Bezahlt",
  "Type": "Typ",
  "Account paid": "Konto bezahlt",
  "Refunded": "Erstattet",
  "Cancel": "Abbrechen",
  "Vender Name": "Verkäufername",
  "AliExpress Shop Name": "Name des AliExpress-Shops",
  "Product allocated": "Produkt zugeordnet",
  "Purchase amount:": "Gesamtbetrag des Einkaufs:",
  "Created at:": "Hergestellt in:",
  "Latest cooperation at:": "Neueste Zusammenarbeit bei:",
  "How to add": "Wie man hinzufügen",
  "By Vendor Name": "Nach Anbietername",
  "Email Security Verification": "E-Mail-Sicherheitsüberprüfung",
  "For security reason.We need to verify your email address.Please click 'Send' to get the verification code in your inbox": "Aus Sicherheitsgründen müssen wir Ihre E-Mail-Adresse bestätigen. Bitte klicken Sie auf 'Senden', um den Bestätigungscode in Ihrem Posteingang zu erhalten",
  "Send": "senden",
  "Verify": "Verifizieren",
  'OK': 'OK',
  "brokerage":"Vermittlung",
  "incorrect input":"falsche Eingabe",
  "withdraw":"zurückziehen",
  "Please enter the withdrawal amount":"Bitte geben Sie den Auszahlungsbetrag ein",
  "tip":"Spitze",
  "Distributor commission withdrawal": "Auszahlung der Vertriebsprovision",
  "Withdrawal method": "Auszahlungsmethode",
  "Withdrawal Amount": "Auszahlungsbetrag",
  "Please enter the commission withdrawal amount": "Bitte geben Sie den Auszahlungsbetrag der Provision ein",
  "Remark": "Anmerkung",
  "Please enter content": "Bitte geben Sie Inhalte ein",
  "My Wallet": "Mein Geldbeutel",
  "余额":"Ausgleich",
  "线下":"offline",
	'提现列表': "Auszahlungsliste",
	"accountPayee": "Freigabestand",
	"提现方式":"Auszahlungsmethode",
	"tradeAmount":"Auszahlungsbetrag",
	"状态": 'Freigabestand',
	"reject":"ablehnen",
	"agree":"zustimmen",  
  '待审核': 'Nicht überprüft',
  "待审批":"Ausstehend",
  "已审批":"Genehmigt",
  '通过': 'passieren',
  '拒绝': 'ablehnen',
  'pleaseCusName':'Kundenname',
  "CreateDate":"Bewerbungszeit",
  "startDate":"Start",
  "endDate":"Ende",
  "to":"Zu",
  "Order Quantity": "Bestellmenge",
  "Commission": "Kommission",
  "customersName":"Kundenname",
  "Total commission": "Gesamtprovision",
  "Enter order number": "Bestellnummer eingeben",
  "My Balance": "Mein Gleichgewicht",
  "Rewards QTY left": "Belohnungen QTY links",
  "Your Total Rewards": "Ihre Gesamtprämien",
  "orders QTY": "Bestellungen Menge",
  "Charging Method": "Lademethode",
  "Payment":"Zahlung",
  "Pay with Credit Card": "Mit Kreditkarte bezahlen",
  "Paypal Payment Amount": "Paypal-Zahlungsbetrag",
  "The amount of each recharge must be more than 100 dollars.": "Der Betrag jeder Aufladung muss mehr als 100 Dollar betragen.",
  "Please verify your email address here,": "Bitte bestätigen Sie hier Ihre E-Mail-Adresse,",
  "Withdraw Cash": "Geld abheben",
  "Verify Email": "E-Mail bestätigen",
  "Bill Number": "Rechnungsnummer",
  "Order Code": "Bestellcode",
  "Transfer ID": "Transfer-ID",
  "wallet recharge": "Brieftasche aufladen",
  "The minimum payment is $100.": "Die Mindestzahlung beträgt 100 $.",
  "Powered by": "Bereitgestellt von",
  "Card Number": "Kartennummer",
  "Expiration": "Ablauf",
  "All transactions are secure and encrypted.": "Alle Transaktionen sind sicher und verschlüsselt.",
  "Account Balance": "Kontostand",
  "Withdrawal amount": "Auszahlungsbetrag",
  "We Accept": "Wir akzeptieren",
  "We will charge you 5% fee when withdrawing.": "Beim Abheben berechnen wir Ihnen 5% Gebühr.",
  "Send Verification Code": "Bestätigungscode senden",
  "The verification code will be sent to your email": "Der Bestätigungscode wird an Ihre E-Mail gesendet",
  "Please enter your withdrawal amount": "Bitte geben Sie Ihren Auszahlungsbetrag ein",
  "Please enter your account": "Bitte geben Sie Ihr Konto ein",
  "Please enter your account name": "Bitte geben Sie Ihren Kontonamen ein",
  "Please enter the code": "Bitte geben Sie den Code ein",
  "Please enter the recharge amount": "Bitte geben Sie den Aufladebetrag ein",
  "The minimum payment is $100": "Die Mindestzahlung beträgt 100 $",
  "The Code was sent successfully": "Der Code wurde erfolgreich gesendet",
  "Our app is now updating the page you are visiting, we will auto refresh this page in 10 seconds.": "Unsere App aktualisiert jetzt die Seite, die Sie besuchen, wir aktualisieren diese Seite automatisch in 10 Sekunden.",
  "I got it, please refresh it now": "Ich habe es verstanden, bitte aktualisieren Sie es jetzt",
  "Estimated start time:": "Voraussichtliche Startzeit:",
  "Security Verification": "Sicherheitscheck",
  "We noticed you haven't verified your email address. Go to verify now?": "Wir haben festgestellt, dass Sie Ihre E-Mail-Adresse noch nicht bestätigt haben. Jetzt bestätigen?",
  "Verify": "Verifizieren",
  "I see": "Ich sehe",
  'please enter': 'Bitte eingeben',
  "For security reason.We need to verify your email address.": "Aus Sicherheitsgründen müssen wir Ihre E-Mail-Adresse bestätigen.",
  "Please click 'Send' to get the verification code in your inbox": "Bitte klicken Sie auf 'Senden', um den Bestätigungscode in Ihrem Posteingang zu erhalten",
  "Send To:": "Senden an:",
  '请输入': "Bitte eingeben",
  '工单号': 'Arbeitsauftragsnummer',
  '待审核': 'überprüft werden',
  '同意': 'zustimmen',
  '拒绝': 'ablehnen',
  '工单类型': 'Art des Tickets',
  '订单编号': "Bestellnummer",
  '客户名称': "Name des Kunden",
  '店铺': "Geschäft",
  '创建时间': "Erstellungszeitpunkt",
  '审批': "Genehmigung",
  "交易类型": "Art der Transaktion",
  "问题类型": "Fragetyp",
  "问题描述": "Problembeschreibung",
  '退款': "Erstattung",
  '更换': "ersetzen",
  "修改客户相关信息": "Kundenbezogene Informationen ändern",
  '取消订单': "Bestellung stornieren",
  '无跟踪信息': "Keine Tracking-Informationen",
  '跟踪信息长时间未更新': "Die Tracking-Informationen wurden schon lange nicht mehr aktualisiert",
  '物品损坏': "Artikel beschädigt",
  '物品丢失': "Artikel verloren",
  '发送错误产品': "Habe das falsche Produkt geschickt",
  '发送错误地址': "An falsche Adresse gesendet",
	'订单暂停': "Bestellpause",
  '选填': "Optional", 
  'after sales':"nach dem Verkauf",
  'Please select an order first':'Bitte wählen Sie zuerst eine Bestellung aus',
  "售后工单": "Arbeitsauftrag nach dem Verkauf",
}