export default {
  "Product category name": 'Product category name',
  "Category Name": "Category Name",
  'Please enter the first name': 'Please enter the first name',
  'Please enter the last name': 'Please enter the last name',
  'Please enter the address': 'Please enter the address',
  'Please enter the country': 'Please enter the country',
  'Please enter the province': 'Please enter the province',
  'Please enter the city': 'Please enter the city',
  'Please enter the phone': 'Please enter the phone',
  'Please enter the postcode': 'Please enter the postcode',
  'Total Payment': 'Total Payment',
  'Total Merchandise': 'Total Merchandise',
  'SKU Details': 'SKU Details',
  "Commodity Price": "Commodity Price",
  "Set as default shipping address": "Set as default shipping address",
  "Reset": "Reset",
  "Confirm": "Confirm",
  "Subscribe to our newsletter": "Subscribe to our newsletter",
  "Promotions, new products and sales. Directly to your inbox.": "Promotions, new products and sales. Directly to your inbox.",
  "Your email": "Your email",
  "Subscribe": "Subscribe",
  "Company Information": "Company Information",
  "OUR SOCIAL MEDIA": "OUR SOCIAL MEDIA",
  "PRIVACY STATEMENT": "PRIVACY STATEMENT",
  "Terms and Conditions": "Terms and Conditions",
  "Sure": "Sure",
  "My  Profile": "My  Profile",
  "Login/Register": "Login/Register",
  "Logout": "Logout",
  "Warning Tips": "Warning Tips",
  '警告提示': 'Warning Tips',
  '该分类下目前还没有商品': 'There are currently no products under this category',
  '最大建议尺寸 200px * 200px, 图片可自适应': 'Maximum recommended size 200px * 200px, image can be adaptive',
  '添加图片': 'add image',
  '更改': 'change',
  '删除': 'delete',
  'Edit Product': 'Edit Product',
  '请联系供应商，完善站长外部编号': 'Please contact the supplier to improve the external number of the webmaster',
  '返回': 'Back',
  "Please contact the supplier to improve the external number of the webmaster": "Please contact the supplier to improve the external number of the webmaster",
  "Black": "Black",
  "There are currently no products under this category": "There are currently no products under this category",
  "Logo": "Logo",
  "You can find the recent quotations and check offers": "You can find the recent quotations and check offers",
  "Maximum recommended size 200px * 200px, image can be adapted": "Maximum recommended size 200px * 200px, image can be adapted",
  "Add image": "Add image",
  "Change": "Change",
  "Delete": "Delete",
  "Change image": "Change image",
  "Shopify tags": "Shopify tags",
  "New Tag": "New Tag",
  "Shopify type": "Shopify type",
  "Loading": "Loading",
  "Compare Price": "Compare Price",
  "Cost Price": "Cost Price",
  "Product Cost": "Product Cost",
  "Cancel": "Cancel",
  "incorrect input":"incorrect input",
  "Tag name": "Tag name",
  "Distributor commission withdrawal": "Distributor commission withdrawal",
  "Withdrawal method": "Withdrawal method",
  "Withdrawal Amount": "Withdrawal Amount",
  "Please enter the commission withdrawal amount": "Please enter the commission withdrawal amount",
  "Remark": "Remark",
  "Please enter content": "Please enter content",
  "Save": "Save",
  "Close": "Close",
  "余额":"balance",
  "线下":"offline",
  '提现列表': "Withdrawal list",
	"accountPayee": "accountPayee",
	"提现方式":"Withdrawal method",
	"状态": 'Approval Status',
	"tradeAmount":"Withdrawal Amount",
	"reject":"reject",
  'pleaseCusName':'Customer Name',
	"agree":"agree",
  'please enter': 'please enter',
  "CreateDate":"application time",
  "startDate":"start",
  "endDate":"end",
  "to":"to",
  "Selected stores": " Selected stores",
  "Please select the Shopify stores": "Please select the Shopify stores",
  "Shopify": "Shopify",
  "Woocommerce": "Woocommerce",
  "Lazada": "Lazada",
  "Shopee": "Shopee",
  'Action': 'Action',
  "Shopify store": "Shopify store",
  'Change All': 'Change All',
  'Multiply Price': 'Multiply Price',
  'Add Price': 'Add Price',
  "Wayfair": "Wayfair",
  "Open in Web": "Open in Web",
  "Your price":"Your price",
  "Push to Store": "Push to Store",
  " Add Cost Range": " Add Cost Range",
  "Rest of the price ranges": "Rest of the price ranges",
  "Price": "Price",
  "Plus": "Plus",
  "Fail reason": "Fail reason",
  "Multiply": "Multiply",
  "Fixed Price": "Fixed Price",
  "You can set a specific cent value for your price or comparison": "You can set a specific cent value for your price or comparison",
  "price.": "price.",
  "Assign Cent": " Assign Cent",
  "Assign compare at Cent": " Assign compare at Cent",
  "According to store currency": " According to store currency",
  "Apply for products in Import List": "Apply for products in Import List",
  "Add New Tags": "Add New Tags",
  "Are you sure to delete it": "Are you sure to delete it??",
  "Please enter the New tag": "Please enter the New tag",
  "Are you sure you want to push?": "Are you sure you want to push?",
  "Do you want to delete tish task? Tips": "Do you want to delete tish task? Tips",
  "success": "success",
  "error": "error",
  "The update  has started, it will take a while to finish.": "The update  has started, it will take a while to finish.",
  "save successfully": "save successfully",
  "Do you want to delete the": "Möchten Sie die",
  "product(s)?": "product(s)?",
  "Delete success": "Delete success",
  "Do you want to Export the": "Möchten Sie den Export der",
  "Export success": "Export success",
  "Please select the products": "Please select the products",
  "URL required": "URL required",
  "Unit Cost": "Unit Cost",
  "Import success": "Import success",
  "Please select the tags": "Please select the tags",
  "Tags added successfully": "Tags added successfully",
  "You can import and manage your AliExpress / Amazon products here before publishing them to your Shopify store. You can publish a product to different stores and add multiple tags to each products.": "You can import and manage your AliExpress / Amazon products here before publishing them to your Shopify store. You can publish a product to different stores and add multiple tags to each products.",
  "Import Aliexpress'	": "Import Aliexpress / Amazon product URL here and click 'Import Product'",
  "Product Name": "Product Name",
  "Product SKU": "Product SKU",
  "Shipping to": "Shipping to",
  "Shipping fee": "Shipping fee",
  "Add to List": "Add to List",
  "VAT information": "VAT information",
  "Not purchased": "Not purchased",
  "you can click": "you can click",
  "for VAT information": "for VAT information",
  "Cost per unit": "Cost per unit",
  "Clear selection":"Clear selection",
  "Total Quantity selected": "Total Quantity selected",
  "Total Quantity": "Total Quantity",
  "You can find the recent quotations and check offers": "You can find the recent quotations and check offers",
  "Waiting for vendors respond": "Waiting for vendors respond",
  "Vendors responding quotations": "Vendors responding quotations",
  "Archived quotations": "Archived quotations",
  "Fulfilled": "Fulfilled",
  "Unfulfilled": "Unfulfilled",
  "Partially Fulfilled": "Partially Fulfilled",
  "Refunded": "Refunded",
  "Today": "Today",
  "Last 7 days": "Last 7 days",
  "Last 30 days": "Last 30 days",
  "Last 90 days": "Last 90 days",
  "Last 12 months": "Last 12 months",
  "Custom": "Custom",
  "Order number (ascending)": "Order number (ascending)",
  "Order number (descending)": "Order number (descending)",
  "Order number": "Order number",
  "Date (oldest first)": "Date (oldest first)",
  "Date (newest first)": "Date (newest first)",
  "Partially refunded": "Partially refunded",
  "orders selected": "orders selected",
  "orders selected, uncheck": "orders selected, uncheck",
  "orders selected, select all": "orders selected, select all",
  "Request Quotation": "Request Quotation",
  "orders": "orders",
  "Only orders in": "Only orders in",
  "Paid": "Paid",
  "No order selected": "No order selected",
  "Create quotation request": " Create quotation request",
  "can": "can",
  "Has been copied to the clipboard": "Has been copied to the clipboard",
  "Copy to clipboard failed, please copy manually": "Copy to clipboard failed, please copy manually",
  "waiting for responding": "waiting for responding",
  "Cancelled": "Cancelled",
  "Confirmed": "Confirmed",
  "ID Range": "ID Range",
  "Order Includes": "Order Includes",
  "View offers": "View offers",
  "Cancel offers": "Cancel offers",
  "Check offers": "Check offers",
  "View offer detail": "View offer detail",
  "Fulfillment": "Fulfillment",
  "Order": "Order",
  "My Store": "My Store",
  "Date": "Date",
  "Customer": "Customer",
  "Total": "Total",
  "Payment": "Payment",
  "Allocate all to vendor": " Allocate all to vendor",
  "Fulfillment": "Fulfillment",
  "Allocate": "Allocate",
  "button of the page,you can also allocate items from the same SPU to the same vendor":  "button of the page,you can also allocate items from the same SPU to the same vendor",
  "Items": "Items",
  "Product images": "Product images",
  "Details": "Details",
  "Expected price (US$)": "Expected price (US$)",
  "Remark": "Remark",
  "Vendors respond": " Vendors respond",
  "Status": "Status",
  "Purchase Order Reconfirm":"Purchase Order Reconfirm",
  "Vendor name": "Vendor name:",
  "AilExpress shop：": "AilExpress shop：",
  "Invite Vendor": "Invite Vendor",
  "Invite Link:":"Invite Link:",
  "Bonus Detail": "Bonus Detail",
  "Amount($)":"Amount($)",
  "Product name": "Product name",
  "Order Quantity": "Order Quantity",
  "Commission": "Commission",
  "Enter order number": "Enter order number",
  "Product information": "Product information",
  "Total commission": "Total commission",
  "Edit Product": "Edit Product",
  "Shopify vendor": "Shopify vendor",
  "What is your store name?": "What is your store name?",
  "What is your Email?": "What is your Email?",
  "Cancel purchase order": "Cancel purchase order",
  "Use this button to update your Order List if missing orders.The process may take a while to finish.": "Use this button to update your Order List if missing orders.The process may take a while to finish.",
  "Offered Orders": "Offered Orders",
  "Offered Products": "Offered Products",
  "Offer price": "Offer price",
  "View offer detail": "View offer detail",
  "Discard": "Discard",
  "Hold down the slider and drag to the right": "Hold down the slider and drag to the right",
  "In order to keep you informed of the update of the order, we suggest you verify the email": "In order to keep you informed of the update of the order, we suggest you verify the email",
  "orders can be process, what do you want to do now?": "orders can be process, what do you want to do now?",
  "Need new quotation for my orders": "Need new quotation for my orders",
  "Over 30000+ vendors waiting for offer you price": "Over 30000+ vendors waiting for offer you price",
  "Allocate my exist vendors":"Allocate my exist vendors",
  "Just check my orders": "Just check my orders",
  "If you already have cooperative vendors, you can allocate orders to them": "If you already have cooperative vendors, you can allocate orders to them",
  "How to process your orders?": "How to process your orders?",
  "Please select at least one order and quote for your order": "Please select at least one order and quote for your order",
  "Confirm this Offer": "Confirm this Offer",
  "All Orders": "All Orders",
  "All Products": "All Products",
  "Add Tracking": "Add Tracking",
  "Yes, I want to CANCEL this offer": "Yes, I want to CANCEL this offer",
  "Cancel the success": "Cancel the success",
  "Track number": "Track number",
  "Please input the tracking number": "Please input the tracking number",
  "Track company": "Track company",
  "Please select logistics company": "Please select logistics company",
  "Your request has been sent": "Your request has been sent",
  "The receiving address has no logistics method set": "The receiving address has no logistics method set",
  "The order is below the minimum deliverable weight": "The order is below the minimum deliverable weight",
  "The order is below the minimum deliverable volume": "The order is below the minimum deliverable volume",
  "The order is higher than the maximum deliverable weight": "The order is higher than the maximum deliverable weight",
  "The order is higher than the maximum deliverable volume": "The order is higher than the maximum deliverable volume",
  "The following product attributes of this order do not meet the attribute requirements of deliverable products": "The following product attributes of this order do not meet the attribute requirements of deliverable products",
  "The total weight of the goods in this order does not meet the deliverable requirements": "The total weight of the goods in this order does not meet the deliverable requirements",
  "Volume(cm)": "Volume(cm)",
  "Total volume(cm)": "Total volume(cm)",
  "Attribute": "Attribute",
  "I Know": "I Know",
  "Total weight": "Total weight",
  "订单信息": "Order Information",
  "第三方订单编号": "Third party order number",
  "订单编号": "Order Number",
  "店铺": "Stores",
  "创建时间": "Creation time",
  "开泰银行支付": "Kaieteur Bank Payment",
  "Waiting for the payment result to be returned.": "Waiting for the payment result to be returned.",
  "You may need to wait a little while": "You may need to wait a little while",
  "View order": "View order",
  "You have successfully completed your order.": "You have successfully completed your order.",
  "Sorry, we can't complete the payment.": "Sorry, we can't complete the payment.",
  "Pay again": "Pay again",
  "Order No.": "Order No.",
  "Receiving": "Receiving",
  "days": "days",
  "Shipping days ": "Shipping days ",
  "Subtotal": "Subtotal",
  "Freight": "Freight",
  "Discount": "Discount",
  "Exchange": "Exchange",
  "Credits": "Credits",
  "The supplier has opened a credit limit for you. When your account balance is insufficient, you can deduct the credit limit": "The supplier has opened a credit limit for you. When your account balance is insufficient, you can deduct the credit limit",
  "Payment method": "Payment method",
  "There is no alternative method of payment": "There is no alternative method of payment",
  "Quantity of order ": "Quantity of order ",
  "Total amount of goods": "Total amount of goods",
  "Tax Amount": "Tax Amount",
  "Discounted price of goods": "Discounted price of goods",
  "Total freight amount": "Total freight amount",
  "Shop discount": "Shop discount",
  "Amount due (including freight)": " Amount due (including freight)",
  "This document is free of freight": "This document is free of freight",
  "Submit orders": "Submit orders",
  "credit&debit card": "credit&debit card",
  "qr payment": "qr payment",
  "Please select payment method": "Please select payment method",
  "Order Management": "Order Management",
  "Import order": "Import order",
  "Your No.": "Your No.",
  "Export order": "Export order",
  "Filter": "Filter",
  "Clear": "Clear",
  "Create a quote": "Create a quote",
  "Quit quotation": "Quit quotation",
  "Bulk payment": "Bulk payment",
  "Cancel payment": "Cancel payment",
  "Manually synced": "Manually synced",
  "Continue Pay": "Continue Pay",
  "Re quote": "Re quote",
  "Your shop order num": "Your shop order num",
  "Unable to purchase": "Unable to purchase",
  "Products Off shelf": "Products Off shelf",
  "Supply unit price": "Supply unit price",
  "Shop price：": "Shop price：",
  "customersName":"customer Name",
  '待审核': 'Not reviewed',
  '通过': 'pass',
  '拒绝': 'reject',
  "Please make an offer": "Please make an offer",
  "Removed": "Removed",
  "Delete": "Delete",
  "Order Number": "Order Number",
  "Unable to purchase": "Unable to purchase",
  "null     Please make an offer": "null     Please make an offer",
  "warehouse": "warehouse",
  "address": "address",
  "Customer": "Customer",
  "Products": "Products",
  "Product": "Product",
  "Shipping Method": "Shipping Method",
  "Manually synced": "Manually synced",
  "Payment time": "Payment time",
  "Payment amount": "Payment amount",
  "inventory": "inventory",
  "Inventory lock remaining time": "Inventory lock remaining time",
  "Order quantity": "Order quantity",
  "Cancel payment": "Cancel payment",
  "Unable to purchase": "Unable to purchase",
  "null     Please make an offer": "null     Please make an offer",
  "Please make an offer": "Please make an offer",
  "Created Time": "Created Time",
  "Shipping address": "Shipping address",
  "Request Fullfill": "Request Fullfill",
  "Shipping Method": "Shipping Method",
  "Shipping cost": "Shipping cost",
  "pay total": "pay total",
  "Select Payment Method": "Select Payment Method",
  "All transactions are secure and encrypted": "All transactions are secure and encrypted",
  "Pay with": "Pay with",
  "Please do not close the page during payment": "Please do not close the page during payment",
  "Your order has been successfully paid": "Your order has been successfully paid",
  "The selected orders are abnormal and payment cannot be continued": "The selected orders are abnormal and payment cannot be continued",
  "Payment can continue after excluding the following goods": "Payment can continue after excluding the following goods",
  "After removing the off shelf goods, the order will be returned to the": " After removing the off shelf goods, the order will be returned to the",
  "price to be quoted": "price to be quoted",
  "Confirm": "Confirm",
  "选取文件": "Select file",
  "点击下载": "Click to download",
  "订单导入模板": "Order Import Template",
  "请按规范填写表格，仅支持xlsx格式文件": " Please fill in the form according to the specification, only xlsx format files are supported",
  "上传的文件不能超过2M，只处理前99条数据": "The uploaded file cannot exceed 2M, and only the first 99 data are processed",
  "submit": "submit",
  "Quotation to be submitted": "Quotation to be submitted",
  "Vendor quoted order": "Vendor quoted order",
  "Payment Not Received": "Payment Not Received",
  "Pending payment": "Pending payment",
  "Waiting for fulfill": "Waiting for fulfill",
  "Fulfilled": "Fulfilled",
  "Completed": "Completed",
  "Logistics synchronization failed": "Logistics synchronization failed",
  "Unable to purchase": "Unable to purchase",
  "Payment to be completed": "Payment to be completed",
  "Waiting for fulfill": "Waiting for fulfill",
  "Quit quotation": "Quit quotation",
  "danger": "danger",
  "Delivery Address": "Delivery Address",
  "My stores": "My stores",
  "warning": "warning",
  "Vendor Verifying Payment": "Vendor Verifying Payment",
  "Payment result to be returned": "Payment result to be returned",
  "确定删除该商品？": "Sure to delete this item?",
  "确定": "OK",
  "取消": "cancel",
  "Delete Successful!": "Delete Successful!",
  "已取消": "Canceled",
  "Please select an order": "Please select an order",
  "Binding succeeded!": "Binding succeeded!",
  "There is no warehouse, please go to add！": "There is no warehouse, please go to add！",
  "Please choose file": "Please choose file",
  "file cannot over 2MB": "file cannot over 2MB",
  "Create Success": "Create Success",
  "No goods need to be eliminated": "No goods need to be eliminated",
  "Please select an order firs": "Please select an order firs",
  "Successfully": "Successfully",
  "No logistics": "No logistics",
  "Are you sure to abandon payment for the selected order? After abandoning, the order will disappear from the list, which cannot be restored, please operate with caution": "Are you sure to abandon payment for the selected order? After abandoning, the order will disappear from the list, which cannot be restored, please operate with caution",
  "Are you sure to initiate a quotation for the selected order?": "Are you sure to initiate a quotation for the selected order?",
  "Are you sure to abandon the quotation for the selected order? After giving up, it cannot be restored, please operate with caution": "Are you sure to abandon the quotation for the selected order? After giving up, it cannot be restored, please operate with caution",
  "Payment has not arrived": "Payment has not arrived",
  "Payment to be completed": "Payment to be completed",
  "Waiting for fulfillment": "Waiting for fulfillment",
  "Logistics synchronization failed": "Logistics synchronization failed",
  "Unable to purchase": "Unable to purchase",
  "Order ID": "Order ID",
  "SortBy":"SortBy",
  "Date Ranges": "Date Ranges",
  "Fulfillment Status": "Fulfillment Status",
  "Export to": "Export to",
  "Selected": "Selected",
  "Some orders had been fulfilled or refunded": "Some orders had been fulfilled or refunded",
  "Export orders": "Export orders",
  "Attribute Value":"Attribute Value",
  "Abnormal reason":"Abnormal reason",
  "Date type": "Date type",
  "Download tracking information": "Download tracking information",
  "Your Orders are exporting</strong><br/>Your export will be delivered by email to": "Your Orders are exporting</strong><br/>Your export will be delivered by email to",
  "Depending on how many Orders you’re exporting, this could take some time.": "Depending on how many Orders you’re exporting, this could take some time.",
  "<strong>Your Orders are exporting</strong><br/>Your export will be delivered by email to: . <br/>Depending on how many Orders you’re exporting, this could take some time.": "<strong>Your Orders are exporting</strong><br/>Your export will be delivered by email to: . <br/>Depending on how many Orders you’re exporting, this could take some time.",
  "Notice": "Notice",
  "You have": "You have",
  "purchase order(s) need your reconfirm,": " purchase order(s) need your reconfirm,",
  "click here": "click here",
  "to view details.": "to view details.",
  "Vendor shipped": "Vendor shipped",
  "unfulfilled / partially fulfilled)": "unfulfilled / partially fulfilled)",
  "But": "But",
  "You may download these tracking information and fulfilled these orders in your Shopify store manualy.": "You may download these tracking information and fulfilled these orders in your Shopify store manualy.",
  "Download ALL tracking information": "Download ALL tracking information",
  "sync failed": "sync failed",
  "Reconfirm": "Reconfirm",
  "View Details": "View Details",
  "Change another vendor": "Change another vendor",
  "Export": "Export",
  "Cancel this order": "Cancel this order",
  "NOT Joined": "NOT Joined",
  "Amount Due": "Amount Due",
  "Not set up": "Not set up",
  "View Details": "View Details",
  "Send to vendor": "Send to vendor",
  "Share by link": "Share by link",
  "Share link": "Share link",
  "Copy Link": "Copy Link",
  "Title": "Title",
  "Desc": "Desc",
  "Share": "Share",
  "Scan share to WeChat": "Scan share to WeChat",
  "Includes": "Includes",
  "Shopify orders": "Shopify orders",
  "products": "products",
  "invalid":"invalid",
  "Income": "Income",
  "Income will only visible to you": "Income will only visible to you",
  "Allow vendor to process these orders": "Allow vendor to process these orders",
  "Do you want to cancel this order?": "Do you want to cancel this order?",
  "Don't worry, after cancelling this purchase order, you can still find your": "Don't worry, after cancelling this purchase order, you can still find your",
  "Shopify orders in the 'Waiting for sourcing' menu and re-place the purchase order": "Shopify orders in the 'Waiting for sourcing' menu and re-place the purchase order",
  "Discard": "Discard",
  "Change purchase order to another vendor": "Change purchase order to another vendor",
  "Current vendor:": "Current vendor:",
  "Change to new vendor": "Change to new vendor",
  "All my vendors": "All my vendors",
  "Also allocate the products in this purchase order to the new supplier": "Also allocate the products in this purchase order to the new supplier",
  "Order created": "Order created",
  "Order paid": "Order paid",
  "Login to your": "Login to your",
  "Order confirmed": "Order confirmed",
  "Your request is been submitted, please wait one moment.": "Your request is been submitted, please wait one moment.",
  "Copy to clipboard failed, please copy manually": "Copy to clipboard failed, please copy manually",
  "The update process has started, it will take a while to finish.": "The update process has started, it will take a while to finish.",
  "Update Order": "Update Order",
  "You have": "You have",
  "purchase orders": "purchase orders",
  "NEED to finish payment": "NEED to finish payment",
  "Waiting for allocated": "Waiting for allocated",
  "Waiting for sourcing ": "Waiting for sourcing ",
  "Orders processing": "Orders processing",
  "Abnormal order ": "Abnormal order ",
  "Abnormal":"Abnormal",
  "All orders":"All orders",
  "Pending": "Pending",
  "Please select": "Please select",
  "Quote for Orders": "Quote for Orders",
  "Quote for Product": "Quote for Product",
  "Recent Quotations": "Recent Quotations",
  "orders matching your search": "orders matching your search",
  "Export order": "Export order",
  "Email": "Email",
  "Change": "Change",
  "Get captcha": "Get captcha",
  "try again in": "try again in",
  "seconds": "seconds",
  "Please fill in the correct Email address": "Please fill in the correct Email address",
  "User Email modified successfully": "User Email modified successfully",
  "Your Orders are exporting</strong><br/>Your export will be delivered by email to": "Your Orders are exporting</strong><br/>Your export will be delivered by email to",
  "<br/>Depending on how many Orders you’re exporting, this could take some time": "<br/>Depending on how many Orders you’re exporting, this could take some time",
  "Show cancelled orders": "Show cancelled orders",
  "Show all valid orders": " Show all valid orders",
  "unfulfilled / partially fulfilled": " unfulfilled / partially fulfilled",
  "Download NOT FULFILLED tracking information": "Download NOT FULFILLED tracking information",
  "sync failed": " sync failed",
  "Change another vendor": "Change another vendor",
  "Invite vendor to process": "Invite vendor to process",
  "More Action": "More Action",
  "Selected saved! Now you can Check the purchase orders": "Selected saved! Now you can Check the purchase orders",
  "After your confirmation for the payment, we will notify the vendor by email. You can also proactively notify the vendor to speed the delivery up": "After your confirmation for the payment, we will notify the vendor by email. You can also proactively notify the vendor to speed the delivery up",
  "Have you finished the payment?": "Have you finished the payment?",
  "Confirm the payment": "Confirm the payment",
  "The reason must be entered": "The reason must be entered",
  "In this tab, we will only filter your": "In this tab, we will only filter your",
  "Orders which you can allocate to your vendor to process.": " Orders which you can allocate to your vendor to process.",
  "Unfulfilled": "Unfulfilled",
  "Shop name": "Shop name:",
  "Partially fulfilled": "Partially fulfilled",
  "Fulfillment Status": "Fulfillment Status",
  "Allocate all to vendor": "Allocate all to vendor",
  "purchased items are delivered": "purchased items are delivered",
  "Select a vendor": "Select a vendor",
  "Product has been allocated": "Product has been allocated",
  "Vendor name / contact / shop name etc.": "Vendor name / contact / shop name etc.",
  "Here are some other products seems can been allocated to the vendor too, do you want to do it now?": "Here are some other products seems can been allocated to the vendor too, do you want to do it now?",
  "Selected products": "Selected products",
  "Allocate NOW": "Allocate NOW",
  "No thanks": "No thanks",
  "Please select at least one order": "Please select at least one order",
  "Create quotation request?": "Create quotation request?",
  "Copy to clipboard failed, please copy manually": "Copy to clipboard failed, please copy manually",
  "Order ID From:": "Order ID From:",
  "Order ID To:":"Order ID To:",
  "You're going to place": " You're going to place",
  "orders to": "orders to",
  "vendors": " vendors",
  "responded": "responded",
  "You can choose the payment method you can accept, we will submit your": "You can choose the payment method you can accept, we will submit your",
  "orders and options to your vendors": "orders and options to your vendors",
  "Warm tips": "Warm tips",
  "orders NOW": "orders NOW",
  "Place": "Place",
  "You have set the following products to be allocated to the vendors": "You have set the following products to be allocated to the vendors",
  "only once. Therefore, the following items will need to be": "only once. Therefore, the following items will need to be",
  "re-allocated after the orders are generated again.": "re-allocated after the orders are generated again.",
  "By AliExpress VIP link": "By AliExpress VIP link",
  "请输入单号范围": "Please enter the single number range",
  "起始单号必须小于结束单号": "The starting order number must be less than the ending order number",
  "Product title": "Product title",
  "Expected price": "Expected price",
  "Remove entrusted vendor": "Remove entrusted vendor",
  "Reply price": "Reply price",
  "Reply link": "Reply link",
  "published": "published",
  "Yesterday": "Yesterday",
  "Select": "Select",
  "My support": "My support",
  "Connect": "Connect",
  "Reauthorize": "Reauthorize",
  "Choose a vendor": "Choose a vendor",
  "Are you sure you want to remove entrusted vendor?": "Are you sure you want to remove entrusted vendor?",
  "You have successfully hosted the": "You have successfully hosted the",
  "After the successful hosting, your supplier will take the initiative to quote for your pending orders!": "After the successful hosting, your supplier will take the initiative to quote for your pending orders!",
  "Are you sure you want to cancel the hosting store": "Are you sure you want to cancel the hosting store",
  "after you close the hosting, the supplier will not be able to actively quote your order!": "after you close the hosting, the supplier will not be able to actively quote your order!",
  "If you do not check the relevant permissions, it will affect the use of related functions, please operate with caution": "If you do not check the relevant permissions, it will affect the use of related functions, please operate with caution",
  "vendor is authorizing the store on Ourmall Dropshipping App, please be noted.": "vendor is authorizing the store on Ourmall Dropshipping App, please be noted.",
  "Failure reason": "Failure reason",
  "Please enter price'": "Please enter price'",
  "Store products": "Store products",
  "Publish": "Publish",
  "Query": "Query",
  "Reset": "Reset",
  "Price units default to": "Price units default to",
  "Please upload pictures": "Please upload pictures",
  "Please enter title": "Please enter title",
  "Search Products": "Search Products",
  "Please enter": "Please enter",
  "State": "State",
  "Penging": "Penging",
  "Waiting": 'Waiting',
  "Processing": 'Processing',
  "Failed": "Failed",
  "All": "All",
  "Description": "Description:",
  "Edit": "Edit",
  "ADD": "ADD",
  "Add Vendor": "Add Vendor",
  "Edit Vendor": "Edit Vendor",
  "Destination Country": "Destination Country:",
  "My sourcing products": "My sourcing products",
  "Vendor information": "Vendor information",
  "Vendor Contact": "Vendor Contact",
  "Bonus Balance（$）": "Bonus Balance（$）",
  "Sales statistics": "Sales statistics",
  "Please enter description": "Please enter description",
  "Please enter IOSS ID": "Please enter IOSS ID",
  "Please select destination country": "Please select destination country",
  "Please select at least 1 destination country.": "Please select at least 1 destination country.",
  "Please enter an IOSS ID with 12 alphanumeric characters.": "Please enter an IOSS ID with 12 alphanumeric characters.",
  "After saving, your IOSS will be updated to the corresponding order. Are you sure to save?": "After saving, your IOSS will be updated to the corresponding order. Are you sure to save?",
  "Dashboard": "Dashboard",
  "Settings": "Settings",
  "According to the new European VAT e-commerce rules, declaration without lOSS will result in a delay in customs clearance and the recipient will be required to pay VAT, as the parcel will be detained when contact of the recipient is incorrect.": "According to the new European VAT e-commerce rules, declaration without lOSS will result in a delay in customs clearance and the recipient will be required to pay VAT, as the parcel will be detained when contact of the recipient is incorrect.",
  "IOSS Option": "IOSS Option",
  "No IOSS": "No IOSS",
  "The amount of the orders (shipping cost included) will be declared and recipients will be required by the customs to pay VAT when parcels arrive in the destination countries of EU": "The amount of the orders (shipping cost included) will be declared and recipients will be required by the customs to pay VAT when parcels arrive in the destination countries of EU",
  "Please enter correct and valid IOSS IDs, which should be linked to corresponding destination countries. IOSS ID will not be applied if the destination country is not linked to a correct IOSS ID": "Please enter correct and valid IOSS IDs, which should be linked to corresponding destination countries. IOSS ID will not be applied if the destination country is not linked to a correct IOSS ID",
  "My IOSS Settings": "My IOSS Settings",
  "Declare with my own IOSS": "Declare with my own IOSS",
  "Linked Countries": "Linked Countries",
  "Add IOS": "Add IOSSou sure to delete the IO",
  "Unlinked Countries:": "Unlinked Countries:",
  "Are you sure to delete the IOSS?": "Are you sure to delete the IOSS?",
  "More categories": "More categories",
  "Excluding VAT amount": "Excluding VAT amount",
  "VAT am": "Vat Amount",
  "Amount including VAT": "Amount including VAT",
  "AliExpress store information": "AliExpress store information",
  "Detailed seller ratings": "Detailed seller ratings",
  "Recent Products": "Recent Products",
  "Store No.": "Store No.",
  "This store has been open since": "This store has been open since",
  "Positive feedback": "Positive feedback",
  "Business License": "Business License",
  "Item as Described": "Item as Described",
  "Above": "Above",
  "Below": "Below",
  "Average": "Average",
  "Shipping Speed": "Shipping Speed",
  "Detailed Seller Ratings information is unavailable when there're less than 10 rating": "Detailed Seller Ratings information is unavailable when there're less than 10 rating",
  "Add vendors success": "Add vendors success",
  "Add a new vendor": " Add a new vendor",
  "My Vendors": " My Vendors",
  "By Vendor Name": "By Vendor Name",
  "By AliExpress Url": "By AliExpress Url",
  "Please enter your vendor's name": "Please enter your vendor's name",
  "Vendor's Name": "Vendor's Name",
  "Vendor's AliExpress shop or product URL": " Vendor's AliExpress shop or product URL",
  "How to get the vendor's AliExpress shop / product URL?": "How to get the vendor's AliExpress shop / product URL?",
  "Transaction Flow Line": "Transaction Flow Line",
  "Recharge Time": "Recharge Time",
  "Are you sure you want to delete it?": "Are you sure you want to delete it?",
  "Customer name required": "Customer name required",
  "customer successfully edited": "customer successfully edited",
  "customer successfully added": "customer successfully added",
  "Address Management": "Address Management",
  "Add New Address": "Add New Address",
  "Default Shipping Address": "Default Shipping Address",
  "First name": "First name",
  "Last name": "Last name",
  "Phone": "Phone",
  "Push":"Push",
  "Address": "Address",
  "City": "City",
  "Postcode": "Postcode",
  "Province": "Province",
  "Country": "Country",
  "Edit": "Edit",
  "Delete": "Delete",
  "No address": "No address",
  "Do you want to delete?": "Do you want to delete?",
  "delete successfully": "delete successfully",
  "My Allocated Products": "My Allocated Products",
  "save successfully": "save successfully",
  "My Allocated Product": "My Allocated Product",
  "Batch Re-allocate": "Batch Re-allocate",
  "Batch Un-allocate": "Batch Un-allocate",
  "All my stores": "All my stores",
  "UN-allocate Success": "UN-allocate Success",
  "Home": "Home",
  "My Profile": "My Profile",
  "Logout": "Logout",
  "LOGIN": "LOGIN",
  "Attach image file": "Attach image file",
  "Browse": "Browse",
  "Customer Support Email": "Customer Support Email",
  "Send your request": "Send your request",
  "Powered by OurMall": "Powered by OurMall",
  "Privacy policy": "Privacy policy",
  "Terms of use": "Terms of use",
  "Refund Policy": "Refund Policy",
  "Contact Us": "Contact Us",
  "Getting Started": "Getting Started",
  "Main Features": "Main Features",
  "FAQs": "FAQs",
  "This file format is not supported": "This file format is not supported",
  "The file size can not over 2MB": "The file size can not over 2MB",
  "Please input your message": "Please input your message",
  "OurMall Dropshipping - Easy Dropshipping": "OurMall Dropshipping - Easy Dropshipping",
  "Please enter Shopify store name": "Please enter Shopify store name",
  "The question must not be empty": "The question must not be empty",
  "The email must not be empty": "The email must not be empty",
  "Shopify store name": "Shopify store name",
  "Current Unit Cost": "Current Unit Cost",
  "Send Success,We will contact you soon.": "Send Success,We will contact you soon.",
  "Welcome to": "Welcome to",
  "Please sign in or register an account to start the chat.": "Please sign in or register an account to start the chat.",
  "All Items": "All Items",
  "back": "back",
  "Check All": "Check All",
  "Digital Themrmometer Hygrometer Living Ro Digital Themrmometer Hygrometer Living Ro": "Digital Themrmometer Hygrometer Living Ro Digital Themrmometer Hygrometer Living Ro",
  "Bulk Purchase": "Bulk Purchase",
  "Amount": "Amount",
  "Do you want to delete selected items?": "Do you want to delete selected items?",
  "商品列表页": "Product list page",
  "底部": "Bottom",
  "顶部": "Top",
  "Hi, welcome to": "Hi, welcome to",
  "Welcome to ()! Please sign in or register an account to": "Welcome to ()! Please sign in or register an account to",
  "start the chat.": "  start the chat.",
  "Sign in": "Sign in",
  "Register": "Register",
  "Recall": "Recall",
  "wallet": "wallet",
  "balance": "balance",
  "system notification": "system notification",
  "This month Orders": "This month Orders",
  "This month Revenue": "This month Revenue",
  "This month Cost": "This month Cost",
  "Statistics": "Statistics",
  "Reference": "Reference",
  "Show More": "Show More",
  "Quick Summary": "Quick Summary",
  "My Products": "My Products",
  "Waiting to place order": "Waiting to place order",
  "Waiting for tracking code": "Waiting for tracking code",
  "Stores": "Stores",
  "Trade name": "Trade name",
  "Authorized": "Authorized",
  "Purchase price:": "Purchase price:",
  "NOT SET": "NOT SET",
  "Start date": "Start date",
  "End date": "End date",
  "Expected Price": "Expected Price",
  "Product link": "Product link",
  "no product": "no product",
  "Dispatched Items": "Dispatched Items",
  "Valid Invoices": "Valid Invoices",
  "Valid Amount Due": "Valid Amount Due",
  "Order’s items that you can dispatch to vendors": "Order’s items that you can dispatch to vendors",
  "No Picture": "No Picture",
  "No Data": "No Data",
  "Dispatch order’s items to vendors": "Dispatch order’s items to vendors",
  "Share by APP": "Share by APP",
  "Your vendor": "Your vendor",
  "is inviting you to allocate your Orders": " is inviting you to allocate your Orders",
  "Your store name": " Your store name",
  "Dispatch order method": "Dispatch order method",
  "All my unfulfilled / partially fulfilled orders": "All my unfulfilled / partially fulfilled orders",
  "Cycle time": "Cycle time",
  "Every 1 day": "Every 1 day",
  "You can modify your order dispatch rules at any time in the menu 'MyOrders >> Dispatch settings'": " You can modify your order dispatch rules at any time in the menu 'MyOrders >> Dispatch settings'",
  "Remind later": "Remind later",
  "I agree": "I agree",
  "Shopify order": " Shopify order",
  "Dispatch to": "Dispatch to",
  "vendor(s)": "vendor(s)",
  "Dispatch in the Next Cycle": "Dispatch in the Next Cycle",
  "Dispatch Immediately": "Dispatch Immediately",
  "You can modify your store settings at any time in the menu 'My Stores'": "You can modify your store settings at any time in the menu 'My Stores'",
  "Newbie": "Newbie",
  "guide": "guide",
  "Complete  personal information": "Complete  personal information",
  "Authorize to bind the store": "Authorize to bind the store",
  "Publish the push product": "Publish the push product",
  "Start pulling   orders": "Start pulling   orders",
  "Already": "Already",
  "enter": "enter",
  "Add vendors success": "Add vendors success",
  "Please select the products": "Please select the products",
  "Please select the vendor": "Please select the vendor",
  "Please select the circle period": "Please select the circle period",
  "Copy to clipboard failed, please copy manually": "Copy to clipboard failed, please copy manually",
  "Are you sure you want to cancel it?": "Are you sure you want to cancel it?",
  "Disputed order": "Disputed order",
  "Awaiting Response": "Awaiting Response",
  "Search": "Search",
  "Tracking Number": "Tracking Number",
  "Dispute Type": "Dispute Type",
  "Expected Solution": "Expected Solution",
  "Create Date": "Create Date",
  "Last week": "Last week",
  "Last month": "Last month",
  'OK': 'OK',
  "Type": "Type",
  "Last three months": "Last three months",
  "The page you are looking for can't be found.": "The page you are looking for can't be found.",
  "BACK TO HOMEPAGE": "BACK TO HOMEPAGE",
  "Transform Your eCommerce business Into a Powerhouse": "Transform Your eCommerce business Into a Powerhouse",
  "Importify helps people like you to automate their dropshipping business.": "Importify helps people like you to automate their dropshipping business.",
  "Product Research": "Product Research",
  "Are you tired of searching the millions o products on AliExpress for potential winners?": "Are you tired of searching the millions o products on AliExpress for potential winners?",
  "Are you tired of searching the millions o products on AliExpress for potential winners?": "Are you tired of searching the millions o products on AliExpress for potential winners?",
  "One-click import products": "One-click import products",
  "Import Product":"Import Product",
  "Profit": "Profit",
  "Import products from 30+ top dropshipping vendors, Aliexpress, Alibaba, Amazon, Etsy, Dhgate, Lazada, Shopee, Banggood, Taobao, 1688, Gearbest, Walmart, and many more.": "Import products from 30+ top dropshipping vendors, Aliexpress, Alibaba, Amazon, Etsy, Dhgate, Lazada, Shopee, Banggood, Taobao, 1688, Gearbest, Walmart, and many more.",
  "Pricing Rules": "Pricing Rules",
  "Tired of calculating your target profit margin? Create pricing rules before importing, quickly adjust prices for sales.": "Tired of calculating your target profit margin? Create pricing rules before importing, quickly adjust prices for sales.",
  "Product Customization": "Product Customization",
  "Use our chrome extension to modify the title, description, images, variants, pricing of the imported products.": "Use our chrome extension to modify the title, description, images, variants, pricing of the imported products.",
  "Automate your orders": "Automate your orders",
  "The Importify Chrome Extension allows you to fulfill your orders quickly by placing the customer address automatically on the checkout page.": "The Importify Chrome Extension allows you to fulfill your orders quickly by placing the customer address automatically on the checkout page.",
  "Easy vendor Change": "Easy vendor Change",
  "Replace your product vendor with a new vendor and connect them to your store with a click.": "Replace your product vendor with a new vendor and connect them to your store with a click.",
  "Auto Dispatch Collections": "Auto Dispatch Collections",
  " Auto-dispatch products to your Store collections supported on Shopify, WooCommerce, and Jumpseller.": " Auto-dispatch products to your Store collections supported on Shopify, WooCommerce, and Jumpseller.",
  "Split Variants": "Split Variants",
  'Variants': 'Variants',
  "Importify gives you the ability to Split variants into Separate products.": "Importify gives you the ability to Split variants into Separate products.",
  "Connect Existing Products": "Connect Existing Products",
  "If you have products that are already on a Shopify, Woocommerce or Jumpseller store, you can connect and manage them in Importify.": "If you have products that are already on a Shopify, Woocommerce or Jumpseller store, you can connect and manage them in Importify.",
  "Add to Import List": "Add to Import List",
  "Add to Cart": "Add to Cart",
  "View More": "View More",
  "MORE": "MORE",
  "Categories": "Categories",
  "size":"size",
  "Stock Qty": 'Stock Qty',
  "Total Revenue":"Total Revenue",
  "Revenue": "Revenue",
  "Total Cost": "Total Cost",
  "Total Orders": "Total Orders",
  "weight":"weight",
  "GO": "GO",
  "Tag": "Tag",
  "Returnable with in":"Returnable with in",
  "No Account? ": "No Account? ",
  "or Sign in with": "or Sign in with",
  "Already have an account?": "Already have an account?",
  "Find Products": "Find Products",
  "My Import List": "My Import List",
  "Please select a shipping address": "Please select a shipping address",
  "Please select a shipping method":"Please select a shipping method",
  "How to find products? Find products in": "How to find products? Find products in",
  "Products Market! Dropshipping from Worldwide to Worldwide! :": "Products Market! Dropshipping from Worldwide to Worldwide! :",
  "Email must be filled in": "Email must be filled in",
  "CAPTCHA must be filled in": "CAPTCHA must be filled in",
  "New password must be filled in": "New password must be filled in",
  "The new password and confirm password must be the same": "The new password and confirm password must be the same",
  "The password must contain letters, and numbers, Minimum 8 characters": "The password must contain letters, and numbers, Minimum 8 characters",
  "Password reset successful": "Password reset successful",
  "Password must be filled in": "Password must be filled in",
  "IN ALL CATEGORIES": "IN ALL CATEGORIES",
  "Request Quotation": "Request Quotation",
  "More categories": "More categories",
  "There are no products in this category": "There are no products in this category",
  "Place Order": "Place Order",
  "Choose orders": "Choose orders",
  "Manage-Addresses": "Manage-Addresses",
  "Default Address": "Default Address",
  "There is no Shipping method to reach the current address": "There is no Shipping method to reach the current address",
  "Please select address": "Please select address",
  "Order Information": "Order Information",
  "Product Price": "Product Price",
  "Choose the products from your Shopify orders to allocated to your vendor": "Choose the products from your Shopify orders to allocated to your vendor",
  "Click the Allocate to vendo button on the bottom of the page.": "Click the Allocate to vendo button on the bottom of the page.",
  "Tips: You can filter your orders here.": "Tips: You can filter your orders here.",
  "Choose or create a new vendor.": "Choose or create a new vendor.",
  "Click the Allocate button of the page,you can also allocate items from the same SPU to the same vendor": "Click the Allocate button of the page,you can also allocate items from the same SPU to the same vendor",
  "Un-allocate selected products": "Un-allocate selected products",
  "Total vendors": "Total vendors",
  "Total orders": "Total orders",
  "Click the": "Click the",
  "Allocate to vendor": "Allocate to vendor",
  "button on the bottom of the page.": "button on the bottom of the page.",
  "Total Product": "Total Product",
  "Previous": "Previous",
  "Next": "Next",
  "and Automate": "and Automate",
  "your entire dropshipping process": "your entire dropshipping process",
  "Place 1 purchase orders": "Place 1 purchase orders",
  "With just one click, you can easily allocate your stores orders to your vendors and the entire shipping process will be automatically completed.": "With just one click, you can easily allocate your stores orders to your vendors and the entire shipping process will be automatically completed.",
  "Create a purchase orders to your vendor.": "Create a purchase orders to your vendor.",
  "Choose the Shopify orders you want to sourcing,and click the Make a purchase order button of the page.": "Choose the Shopify orders you want to sourcing,and click the Make a purchase order button of the page.",
  "After confirming the operation,The purchase orders will be created successfully.": "After confirming the operation,The purchase orders will be created successfully.",
  "Share purchase orders URL to your vendor and wait your vendor finish the invoice for you.": "Share purchase orders URL to your vendor and wait your vendor finish the invoice for you.",
  "Click the <b>Invite vendor to process</b> button on the bottom of the page.": "Click the <b>Invite vendor to process</b> button on the bottom of the page.",
  "You can send the purchase orders by Skype, WeChat or share by URL link.": "You can send the purchase orders by Skype, WeChat or share by URL link.",
  "I have finished the payment": "I have finished the payment",
  "<b>Invite vendor to process</b>": "<b>Invite vendor to process</b>",
  "Items total": "Items total",
  "You can create a new vendor or select an exist one.": "You can create a new vendor or select an exist one.",
  "Amount Due": "Amount Due",
  "Payment method": "Payment method",
  "Click the button": "Click the button",
  "on the PO page when you finished your payment.": "on the PO page when you finished your payment.",
  "Through AliExpress proxy payment link": "Through AliExpress proxy payment link",
  "Invite vendor to proess purchase order": "Invite vendor to proess purchase order",
  "After you receive the vendor's invoice and finished the payment.": "After you receive the vendor's invoice and finished the payment.",
  "Click the button <b>I have finished the payment</b> on the PO page when you finished your payment.": "Click the button <b>I have finished the payment</b> on the PO page when you finished your payment.",
  "After vendor shipped, Shopify order will be fulfilled automatically.": "After vendor shipped, Shopify order will be fulfilled automatically.",
  "After your vendor shipped your order, the shipping information will be sync to your Shopify store automatically.": "After your vendor shipped your order, the shipping information will be sync to your Shopify store automatically.",
  "Billing History": "Billing History",
  "Billing": "Billing",
  "Quotation": "Quotation",
  "Search by bill number or order number": "Search by bill number or order number",
  "Order Code": "Order Code",
  "Payment Method": "Payment Method",
  "Payment":"Payment",
  "Request for Quote": "Request for Quote",
  "Wait for import": "Wait for import",
  "My Profile": "My Profile",
  "Allocate Orders for vendors": "Allocate Orders for vendors",
  "and Automate <br />your entire dropshipping process": "and Automate <br />your entire dropshipping process",
  "With just one click, you can easily allocate your stores orders to": "With just one click, you can easily allocate your stores orders to",
  "your vendors and the entire shipping process will be automatically": "your vendors and the entire shipping process will be automatically",
  "completed.": "completed.",
  "How does it work?": "How does it work?",
  "Connect to your store": " Connect to your store",
  "Specifications": "Specifications",
  "Allocate orders to your vendors": " Allocate orders to your vendors",
  "The vendor arranges the delivery": "The vendor arranges the delivery",
  "Automatic fulfillment of your orders by": "Automatic fulfillment of your orders by",
  "See what our": "See what our",
  "customers talk about us.": " customers talk about us.",
  "More than 10,000+ customers around the world rely on us!": "More than 10,000+ customers around the world rely on us!",
  "Import List": "Import List",
  "Order": "Order",
  "about": "about",
  "Inquiry":"Inquiry",
  "Waiting": "Waiting",
  "Trial calculation of shipping costs": 'Trial calculation of shipping costs',
  "Return able with in": "Return able with in",
  "Push Setting": "Push Setting",
  "You can import and manage your AliExpress / Amazon products here": "You can import and manage your AliExpress / Amazon products here",
  "before publishing them to your Shopify store. You can publish a": "before publishing them to your Shopify store. You can publish a",
  "product to different stores and add multiple tags to each products.": "product to different stores and add multiple tags to each products.",
  "The Shopify orders still unfulfilled / partially fulfilled even if the vendor had upload tracking information.": "The Shopify orders still unfulfilled / partially fulfilled even if the vendor had upload tracking information.",
  "You may download these tracking information and fulfilled these order in your Shopify store manually.":"You may download these tracking information and fulfilled these order in your Shopify store manually.",
  "Click": "Click",
  "New Type": "New Type",
  "need your reconfirm,": "need your reconfirm,",
  "here": "here",
  "Operate": "Operate",
  "待审批":"Pending",
  "已审批":"Approved",
  "to leam more": " to leam more",
  "Import Produc": "Import Produc",
  "Current Import Task": "Current Import Task",
  "Import Task": "Import Task",
  "Add Tags": "Add Tags",
  "Tag management": "Tag management",
  "Filter by stores": "Filter by stores",
  "Upload Progress:": "Upload Progress:",
  "Filter by tags": "Filter by tags",
  "Select an image": "Select an image",
  "Add Cost Range": "Add Cost Range",
  "Rest of the price ranges": "Rest of the price ranges",
  "You can set a specific cent value for your price or comparison": "You can set a specific cent value for your price or comparison",
  "price.": "price.",
  "Assign Cent": "Assign Cent",
  "According to store currency": "According to store currency",
  "Selected products": "Selected products",
  "Also publish to Online Store": "Also publish to Online Store",
  "Loading time to long, check detailed progress": "Loading time to long, check detailed progress",
  "Do you want to delete this task?": "Do you want to delete this task?",
  "Delete Completed": "Delete Completed",
  "Please select the stores": "Please select the stores",
  "Please enter the tag": "Please enter the tag",
  "Please enter the New tag": "Please enter the New tag",
  "The update  has started, it will take a while to finish.": "The update  has started, it will take a while to finish.",
  "Do you want to delete the ${} product(s)?": "Do you want to delete the ${} product(s)?",
  "Import success": "Import success",
  "Please enter your store name to connect the dashboard page:": "Please enter your store name to connect the dashboard page:",
  "Coming soon": "Coming soon",
  "Track Number": "Track Number",
  "Shopify order number":"Shopify order number",
  "Shopify store Name": "Shopify store Name",
  "Products Market": "Products Market",
  "Qty Available": "Qty Available",
  "Keywords": "Keywords",
  "First Available": "First Available",
  "Sort by": "Sort by",
  "results": "results",
  "Last 14 days": "Last 14 days",
  "Please enter your shopify store name to connect the dashboard page": "Please enter your shopify store name to connect the dashboard page",
  "Assign orders to vender": "Assign orders to vender",
  "Step One: Select not shipped orders": "Step One: Select not shipped orders",
  "Step Two: Confirm and send these orders to the vendor": "Step Two: Confirm and send these orders to the vendor",
  "Choose one vendor": "Choose one vendor",
  "Please choose one vendor first": "Please choose one vendor first",
  "All Stores": "All Stores",
  "Abort to assign": "Abort to assign",
  "Go to Next": "Go to Next",
  "Go": "Go",
  "Confirm and send these orders to this vendor": "Confirm and send these orders to this vendor",
  "You can check the invoice by scan this QR Code or visit this URL by your web browser": "You can check the invoice by scan this QR Code or visit this URL by your web browser",
  "Time Created": " Time Created",
  "Receiver": "Receiver",
  "Company": "Company",
  "Save and change the vendor": "Save and change the vendor",
  "Selected saved": "Selected saved",
  "Now you can": "Now you can",
  "Send purchase orders to the vendor": "Send purchase orders to the vendor",
  "Check the list again": "Check the list again",
  "Send purchase orders to the vendor": "Send purchase orders to the vendor",
  "A new order successfully created": "A new order successfully created",
  "All Unfulfilled Shopify Orders": "All Unfulfilled Shopify Orders",
  "Quick select of Shopify order items": "Quick select of Shopify order items",
  "Shopify order total": "Shopify order total",
  "Order Date": "Order Date",
  "Periods of orders:": "Periods of orders:",
  "Quick select of Shopify order items": "Quick select of Shopify order items",
  "shopify orders have been selected and can now be dispatched to your vendor": "shopify orders have been selected and can now be dispatched to your vendor",
  "Dispatch to the vendor": "Dispatch to the vendor",
  "Please choose an vendor": "Please choose an vendor",
  "Please choose an item": "Please choose an item",
  "A new order successfully created": "A new order successfully created",
  "All shopify unshipped orders": "All shopify unshipped orders",
  "All undelivered shopify orders": "All undelivered shopify orders",
  "shopify orders have been selected and can now be assigned to your suppliers": "shopify orders have been selected and can now be assigned to your suppliers",
  "customers successfully added": "customers successfully added",
  "Payment Record": "Payment Record",
  "Recharge": "Recharge",
  "Payment Record": "Payment Record",
  "trade code": "trade code",
  "Payment Amount": "Payment Amount",
  "Payment Status": "Payment Status",
  "Payment Number": "Payment Number",
  "Payment Date": "Payment Date",
  "You have authorized a Shopify store，you could": "You have authorized a Shopify store，you could",
  "authorize to login": "authorize to login",
  "to view details。": "to view details。",
  "If it is necessary to process your shopify order or to achieve an automatically update for the shipping information toshopify, you could": "If it is necessary to process your shopify order or to achieve an automatically update for the shipping information toshopify, you could",
  "connect your Shopify store": "connect your Shopify store",
  "and let us help you.": "and let us help you.",
  "If you have authorized a Shopify store, after the vendor delivers the items, the shipping information will been synchronized to your Shopify store which you had authorized.": "If you have authorized a Shopify store, after the vendor delivers the items, the shipping information will been synchronized to your Shopify store which you had authorized.",
  "(If there are some Shopify stores in the order list below that  have not been authorized, you can": " (If there are some Shopify stores in the order list below that  have not been authorized, you can",
  "authorize a new one": "authorize a new one",
  "If you need to view your recent orders, please click": "If you need to view your recent orders, please click",
  "My to view details.": "My to view details.",
  "Cancelled": "Cancelled",
  "Purchase Order": "Purchase Order",
  "The vendor has shipped the product(s), but the shipping information has not been sync to your  Shopify store.": "  The vendor has shipped the product(s), but the shipping information has not been sync to your  Shopify store.",
  "Will not ship": "Will not ship",
  "Not shipped": "Not shipped",
  "Quantity": "Quantity",
  "Subtotal": "Subtotal",
  "Logistics fee": "Logistics fee",
  "The webmaster has confirmed the   payment": "The webmaster has confirmed the   payment",
  "The vendor has confirmed the payment": "The vendor has confirmed the payment",
  "The vendor start delivery the orders": "The vendor start delivery the orders",
  "Shipping costs ": "Shipping costs ",
  "Discount": "Discount",
  "Amount Due": "Amount Due",
  "Payment method": "Payment method",
  "Via paypal": "Via paypal",
  "Through AliExpress proxy payment link": "Through AliExpress proxy payment link",
  "Through a bank transfer": "Through a bank transfer",
  "According to the vendor’s setting you can go to": "According to the vendor’s setting you can go to",
  "Aliexpress to buy the item in the vendor’s store": "Aliexpress to buy the item in the vendor’s store",
  "Go to AliExpress NOW": "Go to AliExpress NOW",
  "Invite vendor to process purchase order": "Invite vendor to process purchase order",
  "webmaster has confirmed the payment": "webmaster has confirmed the payment",
  "The vendor has confirmed the payment": "The vendor has confirmed the payment",
  "The vendor start delivery the orders": "The vendor start delivery the orders",
  "The webmaster has confirmed the payment": "The webmaster has confirmed the payment",
  "The vendor has confirmed the payment": "The vendor has confirmed the payment",
  "The vendor start delivery the orders": "The vendor start delivery the orders",
  "According to the vendor’s setting you can go to": "According to the vendor’s setting you can go to",
  "Aliexpress to buy the item in the vendor’s store": "Aliexpress to buy the item in the vendor’s store",
  "Go to AliExpress NOW": "Go to AliExpress NOW",
  "Invite vendor": "Invite vendor",
  "You can download order's shipping information here when I arranges the delivery.": "You can download order's shipping information here when I arranges the delivery.",
  "Timeline": "Timeline",
  "No more comment": "No more comment",
  "You have connected a Shopify store before, for your data security,  please": "You have connected a Shopify store before, for your data security,  please",
  "log in the Shopify account": "log in the Shopify account",
  "you connected and view this invoice's details.": "you connected and view this invoice's details.",
  "当前发票页面仅供预览，如果发送给客户请使用发票编辑页面的'发送发票'功能！": "The current invoice page is for preview purposes only, if you are sending to a customer please use the 'Send Invoice' function on the invoice editing page",
  "发票预览": "Invoice Preview",
  "如图一所示:页面顶部“发送发票”按钮": "As shown in Figure 1: 'Send Invoice' button at the top of the page",
  "如图二所示:页面底部'发送发票'按钮": "As shown in Figure 2: 'Send Invoice' button at the bottom of the page",
  "I knew it": "I knew it",
  "Connect your Shopify store": "Connect your Shopify store",
  "you can dispatch the Shopify orders to your vendors after the authorization and the shipping information will be automatically synchronized to your Shopify Store.": "you can dispatch the Shopify orders to your vendors after the authorization and the shipping information will be automatically synchronized to your Shopify Store.",
  "Enter your existing Shopify URL bellow and click  “connect”, you will be redirect to Shopify to connect your account to (the authorization will be also completed).": "Enter your existing Shopify URL bellow and click  “connect”, you will be redirect to Shopify to connect your account to (the authorization will be also completed).",
  "Connect your Shopify store": "Connect your Shopify store",
  "Connect a store and fulfill your Shopify orders, sync logistics information to your Shopify store automatically.": "Connect a store and fulfill your Shopify orders, sync logistics information to your Shopify store automatically.",
  "Enter your existing Shopify store URL bellow and you'll be  redirect to Shopify to connect your account to": "Enter your existing Shopify store URL bellow and you'll be  redirect to Shopify to connect your account to",
  "采购订单基础信息": "Purchase order base information",
  "订单总数": "Total number of orders",
  "订单商品项": "Order items",
  "合计商品总数": "Total goods",
  "合计采购金额": "Total purchase amount",
  "发货情况统计": "Shipment statistics",
  "已发货商品数": "Number of items shipped",
  "已发货商品额": "Amount of goods shipped",
  "未发货商品数": "Number of items not shipped",
  "未发货商品额": "Amount of unshipped goods",
  "发货率": "Shipment rate",
  "不发货商品数": "Number of items not shipped",
  "不发货商品额": "Amount of non-delivery items",
  "Set the vendor’s SKU": "Set the vendor’s SKU",
  "Set the supply price": "Set the supply price",
  "Set up payment methods": "Set up payment methods",
  "Payment Methods": "Payment Methods",
  "Send the invoice to the customer": "Send the invoice to the customer",
  "The fields to be exported": "The fields to be exported",
  "the sequence is adjustable by dragging forward and backward": "the sequence is adjustable by dragging forward and backward",
  "您还没有选择可导出的字段。请在下方选择。": "You have not yet selected the fields that can be exported. Please select below.",
  "optional fields": "optional fields",
  "unexported fields": "unexported fields",
  "custom field name": "custom field name",
  "is displayed as": "is displayed as",
  "Export order's information to CSV": "Export order's information to CSV",
  "BILL To": "BILL To",
  "Invoice Number": "Invoice Number",
  "Invoice Date": "Invoice Date",
  "Amount Due (US$)": "Amount Due (US$)",
  "供应商已发货，但物流信息未同步到Shopify店铺。": "The supplier has shipped, but the logistics information has not been synced to the Shopify shop.",
  "Reason": "Reason",
  "供应商已发货，物流信息已同步到Shopify店铺。": "The supplier has shipped and the logistics information has been synced to the Shopify shop.",
  "Mark as shipped": "Mark as shipped",
  "The webmaster has confirmed the payment": "The webmaster has confirmed the payment",
  "The vendor has confirmed the payment": "The vendor has confirmed the payment",
  "According to the vendor’s setting you can go to": "According to the vendor’s setting you can go to",
  "Aliexpress to buy the item in the vendor’s store": "Aliexpress to buy the item in the vendor’s store",
  "to finish the payment": "to finish the payment",
  "Go to AliExpress NOW": "Go to AliExpress NOW",
  "The vender has confirmed the payment.": "The vender has confirmed the payment.",
  "Remove from order": "Remove from order",
  "You are deleting this product from purchase order. You can still find it": "You are deleting this product from purchase order. You can still find it",
  "in your 'Waiting for sourcing' menu.": "   in your 'Waiting for sourcing' menu.",
  "Total Amount:": "Total Amount:",
  "Pay with": "Pay with",
  "Your order has been successfully paid and the order amount is": "Your order has been successfully paid and the order amount is",
  "Close Pay": "Close Pay",
  "Remove success": "Remove success",
  "Track number must be entered": "Track number must be entered",
  "Track company must be entered": "Track company must be entered",
  "Track company  must be entered": "Track company  must be entered",
  "Track Url must be entered": "Track Url must be entered",
  "Please enter Shopify store URL": "Please enter Shopify store URL",
  "Under authorization, please wait…": "Under authorization, please wait…",
  "After your confirmation for the payment, we will notify the vendor by email. You can also proactively notify the vendor to speed the delivery up": "After your confirmation for the payment, we will notify the vendor by email. You can also proactively notify the vendor to speed the delivery up",
  "Have you finished the payment": "Have you finished the payment",
  "At least one field must be selected": "At least one field must be selected",
  "The name of the customized header cannot be empty": "The name of the customized header cannot be empty",
  "Export the order information": "Export the order information",
  "上传图片大小不能超过 500kb!": "The size of the uploaded image should not exceed 500kb!",
  "Simple, Transparent Pricing": "Simple, Transparent Pricing",
  "mportify offers three types of plans; Basic plan, Premium plan, and Gold plan.": "mportify offers three types of plans; Basic plan, Premium plan, and Gold plan.",
  "Each account starts with a day trial, and your billing cycle begins after the free trial period has ended.": " Each account starts with a day trial, and your billing cycle begins after the free trial period has ended.",
  "Importify Plans<": "Importify Plans<",
  "Basics": "Basics",
  "Premium": "Premium",
  "Gold": "Gold",
  "Offers": "Offers",
  "Amazon & Aliexpress importer": " Amazon & Aliexpress importer",
  "Semi-automatic order fulfillment (Supported on Aliexpress, Amazon, Dhgate & Wonify)": "  Semi-automatic order fulfillment (Supported on Aliexpress, Amazon, Dhgate & Wonify)",
  "Product Customisation": "Product Customisation",
  "描述文字": "Description text",
  "The tax-inclusive price of an item is calculated based on the item price set by the seller and the applicable tax rate. You can contact the seller for more details.": "The tax-inclusive price of an item is calculated based on the item price set by the seller and the applicable tax rate. You can contact the seller for more details.",
  "VAT rate(Destination only in Germany)": "VAT rate(Destination only in Germany)",
  "Shipping options": "Shipping options",
  "履行费用": "Fulfilment costs",
  "Fulfillment Costs": "Fulfillment Costs",
  "发货时效": "Shipping time",
  "Update Product": "Update Product",
  "Shipping Time": "Shipping Time",
  "All Action": "All Action",
  "After Sales Service": "After Sales Service",
  "product description": "product description",
  "Download the attachment": "Download the attachment",
  "product information": "product information",
  "Product category": "Product category",
  "Specification": "Specification",
  "material": "material",
  "Store price": "Store price",
  "Delivery:   Varies for items shipped from an international location": "Delivery:   Varies for items shipped from an international location",
  "This product has been removed from the shelves": "This product has been removed from the shelves",
  "Return": "Return",
  "Enter Store name": "Enter Store name",
  "Enter vendor name": "Enter vendor name",
  "Open in  website": " Open in  website",
  "Retail price:": "Retail price:",
  "NOT SET": "NOT SET",
  "Supplier price": "Supplier price",
  "GENERAL INFORMATION": "GENERAL INFORMATION",
  "CONTACT": "CONTACT",
  "SOCIAL": "SOCIAL",
  "More": "More",
  "Store Name": "Store Name",
  "Notification Settings": "Notification Settings",
  "I want to receive notfications by": "I want to receive notfications by",
  "Mobile phone": "Mobile phone",
  "Email": "Email",
  "Allow system notifhcations": "Allow system notifhcations",
  "Allow Customer Services from ": "Allow Customer Services from ",
  "Allow Dropshipping News": "Allow Dropshipping News",
  "Save Changes": "Save Changes",
  "Reset All": "Reset All",
  "Purchase List": "Purchase List",
  "Complete your Skype or WhatsApp details to get rewards": "Complete your Skype or WhatsApp details to get rewards",
  "You have completed the Skype or WhatsApp, you can receive a free gift package.": "You have completed the Skype or WhatsApp, you can receive a free gift package.",
  "The captcha was sent successfully": "The captcha was sent successfully",
  "Order Details": "Order Details",
  "Sub Order NO": "Sub Order NO",
  "Sold Out": "Sold Out",
  "Status": "Status",
  "Images": "Images",
  "Product Information": "Product Information",
  "Unit Price": "Unit Price",
  "Quantity": "Quantity",
  "Sub Total": "Sub Total",
  "Grand Total": "Grand Total",
  "Address": "Address",
  "Tracking Number:": " Tracking Number:",
  "Total Amount": "Total Amount",
  "Products Cost": "Products Cost",
  "Cost": "Cost",
  "Weight": "Weight",
  "Shipping": "Shipping",
  "Enter SKU": "Enter SKU",
  "Package": "Package",
  "I agree the": "I agree the",
  "Pay NOW": "Pay NOW",
  "All vendors": "All vendors",
  "Dispatch Status": "Dispatch Status",
  "You could select multiple vendors": "You could select multiple vendors",
  "Shipping Cost Included": "Shipping Cost Included",
  "Do you want to cancel the order?": "Do you want to cancel the order?",
  "Order was successfully cancelled": "Order was successfully cancelled",
  "Choose your recharge package": "Choose your recharge package",
  "Choose your payment method": "Choose your payment method",
  "Payment Record": "Payment Record",
  "Terms of use": "Terms of use",
  "Refund Policy": "Refund Policy",
  "History": "History",
  "Platform Reply": "Platform Reply",
  "Picture": "Picture",
  "You can only upload 5 pictures": "You can only upload 5 pictures",
  "Product Title": "Product Title:",
  "Price Range": "Price Range:",
  "Base Information":"Base Information",
  "Product Links": "Product Links:",
  "Product Description": "Product Description:",
  "Publish": "Publish",
  "Trade Name": "Trade Name",
  "Only matching last": "Only matching last",
  "Save Rule": "Save Rule",
  "Please select the Period": "Please select the Period",
  "Please select the vendor visibility": "Please select the vendor visibility",
  "Automatic dispatch orders setting": "Automatic dispatch orders setting",
  "Show hidden orders": "Show hidden orders",
  "Choose my all Shopify products": "Choose my all Shopify products",
  "shopify orders have been selected and can now be dispatched to your vendors": "shopify orders have been selected and can now be dispatched to your vendors",
  "Dispatch to the vendor": "Dispatch to the vendor",
  "My all Shopify products": "My all Shopify products",
  "days unfulfilled orders": "days unfulfilled orders",
  "Dispatch rule TEST": "Dispatch rule TEST",
  "According to your settings, we found some data for you:": "According to your settings, we found some data for you:",
  "THE BRIDGE CONNECTING SUPPLIERS AND YOU, MAKING DROPSHIPPING BUSINESS MORE CONVENIENT": " THE BRIDGE CONNECTING SUPPLIERS AND YOU, MAKING DROPSHIPPING BUSINESS MORE CONVENIENT",
  "Your supplier only needs to register an account to quickly build the system": "Your supplier only needs to register an account to quickly build the system",
  "One-click hosting of stores, fast synchronization of orders": "One-click hosting of stores, fast synchronization of orders",
  "Automatically synchronize logistics information": "Automatically synchronize logistics information",
  "Quick Access": "Quick Access",
  "Do you have an OurMall supplier for you": "Do you have an OurMall supplier for you",
  "Already have a supplier": "Already have a supplier",
  "I don't have a supplier": "I don't have a supplier",
  "Let your supplier fulfill your order more systematically, copy the link and send it to your supplier, and immediately invite your supplier to settle in,": "Let your supplier fulfill your order more systematically, copy the link and send it to your supplier, and immediately invite your supplier to settle in,",
  "2015-2021OurMall All Rights Reserved": "2015-2023OurMall All Rights Reserved",
  "Waiting for the payment result to be returned": "Waiting for the payment result to be returned",
  "You may need to wait a little while": "You may need to wait a little while",
  "View order": "View order",
  "You have successfully completed your order": "You have successfully completed your order",
  "Payment failed": "Payment failed",
  "Sorry, we can't complete the payment": "Sorry, we can't complete the payment",
  "Product Details": "Product Details",
  "Please check the purchase products": "Please check the purchase products",
  "out of stock": " out of stock",
  "Bonus": "Bonus",
  "Quality": "Quality",
  "Credits": "Credits",
  "Unable to be sourced":"Unable to be sourced",
  "Combination payment":"Combination payment",
  "Please enter the balance to be used": "Please enter the balance to be used",
  "Rules: The input amount should be less than the order amount, and only two decimal places": "Rules: The input amount should be less than the order amount, and only two decimal places",
  "After the combination payment is enabled, the account balance will be deducted first, and the rest will be paid by other selected methods": "After the combination payment is enabled, the account balance will be deducted first, and the rest will be paid by other selected methods",
  "Payment method": "Payment method",
  "Amount due (including freight)": " Amount due (including freight)",
  "Submit orders": "Submit orders",
  "Please select the goods first!": "Please select the goods first!",
  "Please choose the logistics channel": "Please choose the logistics channel",
  "请稍后重试～": "Please try again later ~",
  "Wholesale order": "Wholesale order",
  "offline order": "offline order",
  "Shipping Address": "Shipping Address",
  "User Name": "User Name",
  "Users login": " Users login",
  "Password": "Password",
  "Add a new store": "Add a new store",
  "Auth Status": "Auth Status",
  "All Status": "All Status",
  "Authorized": "Authorized",
  "Unauthorized": "Unauthorized",
  "Creation date": "Creation date",
  "Auth date:": "Auth date:",
  "no product": " no product",
  "Delete Task": "Delete Task",
  "Sale price": "Sale price",
  "Select the task": "Select the task",
  "Do you want to delete this task?": "Do you want to delete this task?",
  "Create a ticket": "Create a ticket",
  "Request": "Request",
  "Store name:": "Store name:",
  "No Message": "No Message",
  "Send": "Send",
  "Please enter a value greater than 0": "Please enter a value greater than 0",
  "Please enter a value less than the total amount of the order": "Please enter a value less than the total amount of the order",
  "Tell us more about your request": "Tell us more about your request",
  "What is your Shopify store name?": "What is your Shopify store name?",
  "Transaction": "Transaction",
  "normal bill": "normal bill",
  "Account payee": "Account payee",
  "Payment code": "Payment code",
  "Third Party Number": "Third Party Number",
  "The export of time": "The export of time",
  "Petitioner：": "Petitioner：",
  "Payment code": "Payment code",
  "Trade type": "Trade type",
  "Rewards QTY left": "Rewards QTY left",
  "Trade amount": "Trade amount",
  "Payment type":"Payment type",
  "Amount paid/Credit payment":"Amount paid/Credit payment",
  "Payment order": "Payment order",
  "Refund order": "Refund order",
  "Wallet card": "Wallet card",
  "Manual deduction": "Manual deduction",
  "Paying": "Paying",
  "Account paid": "Account paid",
  "Refunded": "Refunded",
  "Cancel": "Cancel",
  "Vender Name": " Vender Name",
  "AliExpress Shop Name": "AliExpress Shop Name",
  "Product allocated": "Product allocated",
  "Purchase amount:": "Purchase amount:",
  "Created at:": "Created at:",
  "Sourcing Now": "Sourcing Now",
  "Latest cooperation at:": "Latest cooperation at:",
  "How to add": " How to add",
  "By Vendor Name": "By Vendor Name",
  "Email Security Verification": "Email Security Verification",
  "For security reason.We need to verify your email address.Please click 'Send' to get the verification code in your inbox": "For security reason.We need to verify your email address.Please click 'Send' to get the verification code in your inbox",
  "Send": "Send",
  "Pay": "Pay",
  "Verify": "Verify",
  "My Wallet": "My Wallet",
  "My Balance": "My Balance",
  "brokerage":"brokerage",
  "withdraw":"withdraw",
  "Please enter the withdrawal amount":"Please enter the withdrawal amount",
  "tip":"tip",
  "Your Total Rewards": "Your Total Rewards",
  "orders QTY": " orders QTY",
  "We Accept": "We Accept",
  "Other details":"Other details",
  "Payment account": "Payment account",
  "Payment voucher": "Payment voucher",
  "Sign up here": "Sign up here",
  "Forgot your password?":"Forgot your password?",
  "Payment instructions：": "Payment instructions：",
  "Charging Method": "Charging Method",
  "Pay with Credit Card": "Pay with Credit Card",
  "Paypal Payment Amount": "Paypal Payment Amount",
  "The amount of each recharge must be more than 100 dollars.": "The amount of each recharge must be more than 100 dollars.",
  "Please verify your email address here,": "Please verify your email address here,",
  "Withdraw Cash": " Withdraw Cash",
  "I see": "I see",
  "Trade status": "Trade status",
  "Enter Verification Code": "Enter Verification Code",
  "Verify Email": "Verify Email",
  "Bill Number": "Bill Number",
  "Order Code": "Order Code",
  "Transfer ID": "Transfer ID",
  "wallet recharge": "wallet recharge",
  "Payment Method": "Payment Method",
  "The minimum payment is $100.": " The minimum payment is $100.",
  "Powered by": "Powered by",
  "Card Number": "Card Number",
  "Expiration": "Expiration",
  "All transactions are secure and encrypted.": "All transactions are secure and encrypted.",
  "Account Balance": "Account Balance",
  "Withdrawal amount": "Withdrawal amount",
  " We will charge you 5% fee when withdrawing.": " We will charge you 5% fee when withdrawing.",
  "Send Verification Code": "Send Verification Code",
  "The verification code will be sent to your email": "The verification code will be sent to your email",
  "Please enter your withdrawal amount": "Please enter your withdrawal amount",
  "Please enter your account": "Please enter your account",
  "Please enter your account name": "Please enter your account name",
  "Please enter the code": "Please enter the code",
  "Please enter the recharge amount": "Please enter the recharge amount",
  "The minimum payment is $100": "The minimum payment is $100",
  "Payment successful": "Payment successful",
  "The Code was sent successfully": "The Code was sent successfully",
  "Our app is now updating the page you are visiting, we will auto refresh this page in 10 seconds.": " Our app is now updating the page you are visiting, we will auto refresh this page in 10 seconds.",
  "I got it, please refresh it now": "I got it, please refresh it now",
  "Estimated start time:": "Estimated start time:",
  "Security Verification": "Security Verification",
  "We noticed you haven't verified your email address. Go to verify now?": "We noticed you haven't verified your email address. Go to verify now?",
  "Verify": "Verify",
  "Email Security Verification": "Email Security Verification",
  "For security reason.We need to verify your email address.": "For security reason.We need to verify your email address.",
  "Send To:": " Send To:",
  "Please click 'Send' to get the verification code in your inbox": "Please click 'Send' to get the verification code in your inbox",
	'请输入': "please enter",
  '工单号': 'Work order number',
  '待审核': 'Pending review',
  '同意': 'agree',
  '拒绝': 'reject',
  "售后工单": "After-sales work order",
  '工单类型': 'Work order type',
  '订单编号': "order number",
  '客户名称': "client's name",
  '店铺': "shop",
  '创建时间': "creation time",
  '审批': "Approval",
  "交易类型": "Transaction Type",
  "问题类型": "question type",
  "问题描述": "Problem Description",
  "afterSales": "afterSales",
  '退款': "Refund",
  '更换': "Replacement",
  "修改客户相关信息": "modify the related information for customer",
  '取消订单': "cancel order",
  '无跟踪信息': "No tracking information",
  '跟踪信息长时间未更新': "Tracking information not update too long time",
  '物品损坏': "Item damage",
  '物品丢失': "Item missing",
  '发送错误产品': "Send wrong product",
  '发送错误地址': "Send wrong address",
	'订单暂停': "On hold order",
  '选填': "optional",  
  'after sales':"after sales",
  'Please select an order first':'Please select an order first',







}
