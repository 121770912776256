import { render, staticRenderFns } from "./App.vue?vue&type=template&id=b2f20c0a&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=b2f20c0a&prod&lang=scss"
import style1 from "./App.vue?vue&type=style&index=1&id=b2f20c0a&prod&lang=scss&scoped=true"
import style2 from "./App.vue?vue&type=style&index=2&id=b2f20c0a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.4_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6._jca7zi6wchtjyzx47wtnuj7qfi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2f20c0a",
  null
  
)

export default component.exports